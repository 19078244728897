import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, Table, TableBody, TableCell, TableHeader, TableHeaderCell, TableRow } from "semantic-ui-react";
import NestedTable from "./NestedTable";
import { categoryOptions, formatDate, formatDuration } from "./Timer";

const SessionsTable = ({ sessions, getSessions, summary }) => {
  const [expandedRows, setExpandedRows] = useState(new Set());

  const dailySessions = sessions.reduce((acc, session) => {
    const date = formatDate(session.start);
    if (!acc[date]) {
      acc[date] = [];
    }
    acc[date].push(session);
    return acc;
  }, {});

  const toggleRow = (id) => {
    const newExpandedRows = new Set(expandedRows);
    if (newExpandedRows.has(id)) {
      newExpandedRows.delete(id);
    } else {
      newExpandedRows.add(id);
    }
    setExpandedRows(newExpandedRows);
  };

  const header = (
    <TableHeader>
      <TableRow>
        <TableHeaderCell></TableHeaderCell>
        <TableHeaderCell>Date</TableHeaderCell>
        <TableHeaderCell>Total</TableHeaderCell>
        {categoryOptions.map((category, index) => (
          <TableHeaderCell key={index}>{category.text}</TableHeaderCell>
        ))}

      </TableRow>
    </TableHeader>
  );

  const summaryEntries = Object.entries(summary);

  const rows = (
    summaryEntries.map((entry, index) => {
      const total = categoryOptions.reduce((acc, category) => (acc + (entry[1][category.value] || 0)), 0);
      return (
        <React.Fragment key={index}>
          <TableRow
            warning={expandedRows.has(entry[0]) ? true : false}
          >
            <TableCell>
              <Button
                basic
                icon={expandedRows.has(entry[0]) ? "angle down" : "angle right"}
                size="mini"
                onClick={() => toggleRow(entry[0])}
              />
            </TableCell>
            <TableCell>{formatDate(entry[0])}</TableCell>
            <TableCell>
              <h4>{formatDuration(total)}</h4>
            </TableCell>
            {categoryOptions.map((category, index) => (
              <TableCell key={index}>
                {entry[1][category.value] == undefined ? 0 : formatDuration(entry[1][category.value])}
              </TableCell>
            ))}
          </TableRow>
          {expandedRows.has(entry[0]) && (
            <TableRow>
              <TableCell colSpan="10">
                <NestedTable getSessions={getSessions} sessions={dailySessions[formatDate(entry[0])]} />
              </TableCell>
            </TableRow>
          )}
        </React.Fragment>
      );
    })
  );

  return (
    <Table >
      {header}
      <TableBody>
        {rows}
      </TableBody>
    </Table>
  );
};

SessionsTable.propTypes = {
  sessions: PropTypes.arrayOf(PropTypes.object).isRequired,
  getSessions: PropTypes.func.isRequired,
  summary: PropTypes.object.isRequired,

};

export default SessionsTable;
