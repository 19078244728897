import _ from "lodash";
import PropTypes from "prop-types";
import React, {Component} from "react";
import {connect} from "react-redux";
import {Icon, Grid} from "semantic-ui-react";
import ReportTitleRow from "./ReportTitleRow";
import ReportContentRow from "./ReportContentRow";
import {postForm} from "../../actions/actions_form";
import {errorsClear} from "../../actions/actions_errors";
import {GROUPED_BY} from "./utils";


export class ReportTable extends Component {
  static propTypes = {
    auth: PropTypes.object.isRequired,
    rows: PropTypes.array.isRequired,
    orderBy: PropTypes.object,
    groupedBy: PropTypes.string,
    updateFilters: PropTypes.func.isRequired,
    onTaskAccept: PropTypes.func.isRequired,
    onSendToGL: PropTypes.func.isRequired,
    onUserAccept: PropTypes.func.isRequired,
    onTaskHoursChange: PropTypes.func.isRequired
  };

  static tableHeaders = {
    [GROUPED_BY.USER]: [
      {
        title: "User",
        key: "user__username",
        width: 2,
        id: 0
      },
      {
        title: "User Type",
        key: "user__profile__type",
        width: 2,
        id: 1
      },
      {
        title: "Company",
        key: "user__profile__company__name",
        width: 2,
        id: 2
      },
      {
        title: "Deadline",
        key: null,
        width: 2,
        id: 3
      },
      {
        title: "Timer",
        key: null,
        width: 1,
        id: 4
      },
      {
        title: "Auto",
        key: null,
        width: 1,
        id: 5
      },
      {
        title: "Manual",
        key: null,
        width: 1,
        id: 6
      },
      {
        title: "Status",
        key: "status",
        width: 2,
        id: 7
      },
      {
        title: "Options",
        key: null,
        width: 3,
        id: 8
      }
    ],
    [GROUPED_BY.TASK]: [
      {
        title: "Task",
        key: "slug",
        width: 4,
        id: 0
      },
      {
        title: "Language",
        key: "language",
        width: 2,
        id: 1
      },
      {
        title: "Deadline",
        key: "deadline",
        width: 2,
        id: 2
      },
      {
        title: "Timer",
        key: null,
        width: 1,
        id: 3
      },
      {
        title: "Auto",
        key: null,
        width: 1,
        id: 4
      },
      {
        title: "Manual",
        key: null,
        width: 1,
        id: 5
      },
      {
        title: "Status",
        key: null,
        width: 3,
        id: 6
      }
    ],
    [GROUPED_BY.PROJECT]: [
      {
        title: "Project",
        key: "name",
        width: 3,
        id: 0
      },
      {
        title: "Deadline",
        key: "deadline",
        width: 3,
        id: 1
      },
      {
        title: "Timer",
        key: null,
        width: 1,
        id: 2
      },
      {
        title: "Auto",
        key: null,
        width: 1,
        id: 3
      },
      {
        title: "Manual",
        key: null,
        width: 1,
        id: 4
      },
      {
        title: "Status",
        key: null,
        width: 3,
        id: 5
      }
    ],
    [GROUPED_BY.TASK_AND_PROJECT]: [
      {
        title: "Project",
        key: "name",
        width: 3,
        id: 0
      },
      {
        title: "Deadline",
        key: "deadline",
        width: 3,
        id: 1
      },
      {
        title: "Timer",
        key: null,
        width: 1,
        id: 2
      },
      {
        title: "Auto",
        key: null,
        width: 1,
        id: 3
      },
      {
        title: "Manual",
        key: null,
        width: 1,
        id: 4
      },
      {
        title: "Status",
        key: null,
        width: 3,
        id: 5
      }
    ]
  };

  constructor(props) {
    super(props);
    this.state = {
      activeIndex: -1
    };
  }

  sortReportsBy = (key) => {
    const currOrderBy = this.props.orderBy;
    let orderBy = key;

    if (currOrderBy === key) {
      orderBy = `-${key}`;
    } else if (currOrderBy === `-${key}`) {
      orderBy = null;
    }

    this.props.updateFilters({order_by: orderBy});
  };

  onClick = (event, idx) => {
    const {activeIndex} = this.state;
    const newIndex = activeIndex === idx ? -1 : idx;

    this.setState({activeIndex: newIndex});
  };

  renderColumnHeaderContent = ({
    title,
    key,
    width,
    id
  }) => {
    if (key) {
      const {orderBy} = this.props;
      const sortSignName = orderBy === key ? "caret down" : "caret up";
      return (
        <Grid.Column
          key={id}
          width={width}
          className={"sortable-header"}
          onClick={() => this.sortReportsBy(key)}
        >{title}
          {
            orderBy && (orderBy === key || orderBy === `-${key}`) &&
            <Icon name={sortSignName}/>
          }
        </Grid.Column>);
    } else {
      return <Grid.Column key={id} width={width}>{title}</Grid.Column>;
    }
  };

  render() {
    const {
      groupedBy,
      rows: reports
    } = this.props;

    let tableHeaders = ReportTable.tableHeaders[groupedBy];
    const columnCount = tableHeaders.length;

    const headers = (
      <Grid.Row className={"header"}>
        {tableHeaders.map(this.renderColumnHeaderContent)}
      </Grid.Row>
    );

    const rows = _.map(reports, (report, idx) => (
      <Grid.Row className={"body"} key={report.id}>
        <Grid.Column width={16}>
          <Grid width={16} columns={columnCount} textAlign="center" divided>
            <ReportTitleRow
              auth={this.props.auth}
              groupedBy={groupedBy}
              report={report}
              idx={idx}
              reportId={report.id}
              onClick={this.onClick}
              onUserAccept={this.props.onUserAccept}
              active={this.state.activeIndex === idx}
            />
            <ReportContentRow
              auth={this.props.auth}
              groupedBy={groupedBy}
              active={this.state.activeIndex === idx}
              activeIndex={this.state.activeIndex}
              report={report}
              idx={idx}
              reportId={report.id}
              onAccept={this.props.onTaskAccept}
              onSendToGL={this.props.onSendToGL}
              onHoursChange={this.props.onTaskHoursChange}
              onClick={this.onClick}
            />
          </Grid>
        </Grid.Column>
      </Grid.Row>
    ));

    return (
      <Grid container columns={columnCount} className={"table"}>
        {headers}
        {rows}
      </Grid>
    );
  }
}

function mapStateToProps(state) {
  return {auth: state.auth};
}

export default connect(
  mapStateToProps, {
    postForm,
    errorsClear
  }
)(ReportTable);
