import React, { Component } from "react";
import { Grid, Table, Icon } from "semantic-ui-react";
import config from "../../config/config";
import PropTypes from "prop-types";
import ajax from "../../helpers/ajax";
import { secondsToHm } from "./utils";

export default class SessionsSummary extends Component {
  static propTypes = {
    taskReportId: PropTypes.number,
  };

  constructor(props) {
    super(props);

    this.state = {
      sessions: null,
      showSessions: false,
    };
  }

  loadSessions = () => {
    var element = document.getElementById(`task-row-${this.props.taskReportId}`)
    if (this.state.sessions) {
      if (!this.state.showSessions) {
        element.classList.add("active")
      }
      else {
        element.classList.remove("active")
      }
      this.setState((prevState) => ({
        ...prevState,
        showSessions: !prevState.showSessions,
      }));
      return;
    }
    element.classList.add("active")
    ajax
      .get([config.SESSIONS_SUMMARY, this.props.taskReportId])
      .then((response) => {
        this.setState((prevState) => ({
          ...prevState,
          sessions: response.data,
          showSessions: true,
        }));
      });
  };

  render() {
    return (
      <div style={{ width: "100%" }} className={`session-summary ${this.state.showSessions? "active": ""}`}>
        <Icon
          size={"big"}
          onClick={() => this.loadSessions()}
          name={this.state.showSessions ? "angle up" : "angle down"}
        />
        <Grid.Row className="test" verticalAlign="middle">
          <Grid.Column>
            {this.state.showSessions && (
              <div>
                <span>Timer categories</span>
                <Table className="fixed-table" style={{marginBottom: "5px"}}>
                  <Table.Header>
                    {Object.keys(this.state.sessions).map((key, index) => (
                      <Table.HeaderCell style={{paddingBlock: "5px"}} key={index}>{key}</Table.HeaderCell>
                    ))}
                  </Table.Header>
                  <Table.Row>
                    {Object.values(this.state.sessions).map((key, index) => (
                      <Table.Cell key={index}>{secondsToHm(key)}</Table.Cell>
                    ))}
                  </Table.Row>
                </Table>
              </div>
            )}
          </Grid.Column>
        </Grid.Row>
      </div>
    );
  }
}
