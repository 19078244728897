import React from "react";
import PropTypes from "prop-types";
import { TableBody, TableHeader, TableHeaderCell, TableRow, Table } from "semantic-ui-react";
import SessionRow from "./SessionRow";

const NestedTable = ({ sessions, getSessions }) => {

  return (
    <Table >
      <TableHeader>
        <TableRow>
          <TableHeaderCell >Category</TableHeaderCell>
          < TableHeaderCell >Duration</TableHeaderCell>
          <TableHeaderCell >Time</TableHeaderCell>
          <TableHeaderCell >Notes</TableHeaderCell>
          <TableHeaderCell ></TableHeaderCell>
        </TableRow>
      </TableHeader>
      <TableBody>
        {sessions.map((session, index) => (
          <SessionRow getSessions={getSessions} key={index} session={session} />
        ))}
      </TableBody>
    </Table>
  );
};

NestedTable.propTypes = {
  sessions: PropTypes.array.isRequired,
  getSessions: PropTypes.func.isRequired,
};

export default NestedTable;
