import _ from "lodash";
import React, { Component } from "react";
import {
  Grid,
  Modal,
  Icon,
  Checkbox,
  Button,
  Input,
  Dropdown,
} from "semantic-ui-react";
import fs from "file-saver";
import { Buffer } from "buffer";
import { connect } from "react-redux";
import { toastr } from "react-redux-toastr";
import "react-dates/initialize";
import PropTypes from "prop-types";

import ajax from "../../helpers/ajax";
import config from "../../config/config";
import { GROUPED_BY } from "./utils";

import "../Tasks.css";
import "react-dates/lib/css/_datepicker.css";

export class ReportExport extends Component {
  static propTypes = {
    auth: PropTypes.shape({
      is_company_coordinator: PropTypes.bool,
    }).isRequired,
    groupedBy: PropTypes.string,
    reports: PropTypes.array,
    closeModal: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.exportReports = this.exportReports.bind(this);
    this.state = {
      badges: [],
      searched_username: [],
      open_modal_window_badge: false,
      columns_selected: [],
      template: "",
      button_disabled: false,
      columnsToExport: [],
      active: false,
    };
  }

  template_choices = [
    { key: "", value: "", text: "" },
    { key: "ELE", value: "ELE", text: "ELE" },
    { key: "EC", value: "EC", text: "External Company" },
    { key: "SR", value: "SR", text: "Samsung Remote" },
  ];

  all_columns = [
    { value: "deadline", label: "Deadline" },
    { value: "project", label: "Project" },
    { value: "module", label: "Module" },
    { value: "task_name", label: "Task Name" },
    { value: "task_language", label: "Task Language" },
    { value: "description", label: "Description" },
    { value: "answers_count", label: "Answers Count" },
    { value: "evaluation_count", label: "Evaluation Count" },
    { value: "review_count", label: "Review Count" },
    { value: "evaluation_review_count", label: "Evaluation Review Count" },
    { value: "hours_timer", label: "Timer Hours" },
    { value: "hours_auto", label: "Auto Hours" },
    { value: "hours_manual", label: "Manual Hours" },
    { value: "hours_accepted", label: "Accepted Hours" },
    { value: "hours_accepted_type", label: "Accepted Hours Type" },
    {
      value: "person_accepting_or_sending_to_gl_reports",
      label: "Person Accepting or Sending Reports",
    },
    {
      value: "date_of_accepting_or_sending_to_gl_reports",
      label: "Date of Accepting or Sending Reports",
    },
    { value: "coordinator_comment", label: "Coordinator Comment" },
    { value: "gl_accepting", label: "GL Accepting" },
    { value: "date_of_gl_accepting", label: "Date of GL Accepting" },
    { value: "full_name", label: "Full Name" },
    { value: "login", label: "Login" },
    { value: "linguist_language", label: "Linguist Language" },
    { value: "nativity", label: "Nativity" },
    { value: "company", label: "Company" },
    { value: "first_activity", label: "Users First Activity" },
    { value: "last_activity", label: "Users Last Activity" },
    { value: "project_hours_timer", label: "Project Timer Hours" },
    { value: "project_hours_auto", label: "Project Auto Hours" },
    { value: "project_hours_manual", label: "Project Manual Hours" },
  ];

  allPreset() {
    let columns_selected = this.all_columns.map((item) => item.value);
    this.setState({ columns_selected }, this.displayColumns);
  }

  nonePreset() {
    const columns_selected = [];
    this.setState({
      columns_selected,
      columnsToExport: [],
    });
  }

  exportReports() {
    this.setState({ button_disabled: true });
    let report_ids = [];
    const reports = this.props.reports;

    switch (this.props.groupedBy) {
      case GROUPED_BY.USER:
        for (let i = 0; i < reports.length; i++) {
          for (let j = 0; j < reports[i].tasks_reports.length; j++) {
            report_ids.push(reports[i].tasks_reports[j]["id"]);
          }
        }
        break;
      case GROUPED_BY.TASK_AND_PROJECT:
        for (let i = 0; i < reports.length; i++) {
          for (let j = 0; j < reports[i].reports.length; j++) {
            for (let x = 0; x < reports[i].reports[j].reports.length; x++) {
              report_ids.push(reports[i].reports[j].reports[x]["id"]);
            }
          }
        }
        break;
      default:
        for (let i = 0; i < reports.length; i++) {
          for (let j = 0; j < reports[i].reports.length; j++) {
            report_ids.push(reports[i].reports[j]["id"]);
          }
        }
        break;
    }

    let columns_selected = this.state.columns_selected;

    if (this.state.template) {
      columns_selected = [];
    }

    ajax
      .post(config.LINGUIST_EXPORT_REPORTS, {
        data: {
          report_ids: report_ids,
          columns: columns_selected,
          template: this.state.template,
          filename_fragment: this.state.filenameFragment,
        },
        responseType: "arraybuffer",
      })
      .then((result) => {
        const blob = new Blob([result.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8",
        });
        fs.saveAs(blob, result.headers["x-filename"]);
        this.props.closeModal();
      })
      .catch((error) => {
        const buffer = Buffer.from(error.response.data);
        try {
          const errorData = JSON.parse(buffer.toString());
          const errorMessage = errorData.details || "Failed to generate report";
          toastr.error(errorMessage);
        } catch (e) {
          toastr.error("Failed to generate report");
        }
      })
      .finally(() => {
        this.setState({ button_disabled: false });
      });
  }

  displayColumns = () => {
    let columnsToExport = this.state.columns_selected;
    let template = this.state.template;

    if (template) {
      this.setState({ columns_selected: [] });
    }

    this.setState({ columnsToExport });
  };

  toggleCheckboxToUpdate = (event, input) => {
    let columns_selected = this.state.columns_selected;
    let template = this.state.template;
    if (!template) {
      if (input.checked === true) {
        if (_.includes(columns_selected, input.value)) {
          return;
        }
        columns_selected.push.apply(columns_selected, [input.value]);
      } else {
        if (_.includes(columns_selected, input.value)) {
          columns_selected = _.pull(columns_selected, input.value);
        }
      }
      this.setState({ columns_selected }, this.displayColumns);
    }
  };

  exportGridColumns = 5;
  exportGridRows = Math.ceil(this.all_columns.length / this.exportGridColumns);

  render() {
    return (
      <Modal
        dimmer={true}
        open={true}
        onClose={this.props.closeModal}
        closeonrootnodeclick={"true"}
        className={"ReportExport"}
      >
        <Modal.Header>
          <Icon name={"external"} />
          Export Reports
        </Modal.Header>
        <Modal.Content>
          <Grid columns={4}>
            <Grid.Row stretched>
              <Grid.Column className={"ui center aligned"}>
                <Input
                  placeholder="Filename"
                  value={this.state.filenameFragment || ""}
                  onChange={(e) =>
                    this.setState({ filenameFragment: e.target.value })
                  }
                />
              </Grid.Column>
              <Grid.Column className={"ui center aligned"}>
                <Dropdown
                  placeholder="Specification template"
                  selection
                  options={this.template_choices}
                  value={this.state.template}
                  onChange={(e, { value }) =>
                    this.setState({ template: value })
                  }
                />
              </Grid.Column>
              <Grid.Column className={"ui center aligned"}>
                <Button
                  className={"datahive-dark-grey-button"}
                  disabled={this.state.template}
                  onClick={() => {
                    this.nonePreset();
                  }}
                >
                  Select none
                </Button>
              </Grid.Column>
              <Grid.Column className={"ui center aligned"}>
                <Button
                  className={"datahive-dark-grey-button"}
                  disabled={this.state.template}
                  onClick={() => {
                    this.allPreset();
                  }}
                >
                  Select all
                </Button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <Grid
            columns={6}
            style={{
              ...(this.state.template && {
                opacity: 0.5,
                pointerEvents: "none",
              }),
            }}
          >
            {Array.from({ length: this.exportGridRows }).map((_, rowIndex) => (
              <Grid.Row key={`row-${rowIndex}`} centered>
                {Array.from({ length: this.exportGridColumns }).map(
                  (_, colIndex) => {
                    const position =
                      rowIndex * this.exportGridColumns + colIndex;
                    const value = this.all_columns[position].value;
                    const label = this.all_columns[position].label;
                    return (
                      <Grid.Column key={`col-${colIndex}`}>
                        <Checkbox
                          value={value}
                          label={label}
                          checked={this.state.columns_selected.includes(value)}
                          onClick={this.toggleCheckboxToUpdate}
                        />
                      </Grid.Column>
                    );
                  }
                )}
              </Grid.Row>
            ))}
          </Grid>
        </Modal.Content>
        <Modal.Actions className={"centered"}>
          <Button
            disabled={this.state.button_disabled}
            className={"datahive-yellow-button"}
            onClick={() => {
              this.exportReports();
            }}
          >
            Export
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return { auth: state.auth };
}

export default connect(mapStateToProps)(ReportExport);
