import _ from "lodash";

import React, { Component } from "react";
import { Button, Grid, Icon, Label, Popup } from "semantic-ui-react";

import config from "../../config/config";
import {
  TASK_MONTH_REPORT_STATUS_NOT_ACCEPTED,
  TASK_MONTH_REPORT_STATUS_PRE_ACCEPT,
  TASK_MONTH_REPORT_STATUS_TO_GL_ACCEPT,
} from "../../constraints/Ele";
import { Link, withRouter } from "react-router-dom";
import { ReportHistoryPopup, ReportAcceptedPopup } from "./ReportHistoryPopup";
import { string_to_slug } from "../../helpers/utils";
import {
  getHoursAcceptable,
  GROUPED_BY,
  roundHours,
  secondsToHm,
} from "./utils";
import PropTypes from "prop-types";
import SessionsSummary from "./SessionsSummary";
import SendToGLModal from "./SendToGLModal";
import AcceptByGLModal from "./AcceptByGLModal";
import HoursInput from "../../components/HoursInput";

export class TaskReportRow extends Component {
  static propTypes = {
    taskReport: PropTypes.shape({
      hours: PropTypes.number,
      sessions_duration: PropTypes.number,
      id: PropTypes.number,
      title: PropTypes.string,
      language: PropTypes.string,
      activity: PropTypes.object,
      description: PropTypes.string,
      status: PropTypes.string,
      type: PropTypes.string,
      answers: PropTypes.number,
      enable_evaluations: PropTypes.bool,
      expected_pace: PropTypes.number,
      user: PropTypes.shape({
        username: PropTypes.string,
        company_name: PropTypes.string,
      }),
    }).isRequired,
    groupedBy: PropTypes.string,
    onHoursChange: PropTypes.func.isRequired,
    auth: PropTypes.shape({
      is_superuser: PropTypes.bool,
      is_staff: PropTypes.bool,
      is_gl: PropTypes.bool,
    }),
    onAccept: PropTypes.func.isRequired,
    onSendToGL: PropTypes.func.isRequired,
    report: PropTypes.object,
    idx: PropTypes.number,
    allTime: PropTypes.bool,
  };

  constructor(props) {
    super(props);

    this.state = {
      hours: props.taskReport.hours,
      editable: false,
    };
  }

  onHoursChange = (event, data) => {
    let hours = parseFloat(data.value);
    hours = _.isNaN(hours) ? this.state.hours : hours;
    hours = roundHours(hours);
    this.setState({ hours: hours });
  };

  onSaveClick = () => {
    this.setState({ editable: false }, () => {
      this.props.onHoursChange(this.props.taskReport, this.state.hours);
    });
  };

  renderButton = (status, status_value) => {
    let className, color, value, icon;
    if (status_value === "TO_GL_ACCEPT") {
      status = "Accept";
    }
    if (status) {
      className = "datahive-button";
      color = "green";
      icon = "checkmark box";
      value = this.props.auth.is_superuser ? "Accept" : "Pre-Accept";
    } else {
      className = "datahive-button";
      color = "red";
      value = "Reject";
      icon = "window close outline";
    }

    let onClick = null;
    switch (this.props.groupedBy) {
      case GROUPED_BY.USER:
        onClick = () =>
          this.props.onAccept(this.props.report, status, this.props.idx);
        break;
      default:
        onClick = () => this.props.onAccept(this.props.taskReport, status);
        break;
    }

    if (status_value === "TO_GL_ACCEPT" && this.props.auth.is_gl) {
      return this.props.groupedBy === GROUPED_BY.USER ? (
        <AcceptByGLModal
          onAccept={this.props.onAccept}
          auth={this.props.auth}
          status={status}
          taskReport={this.props.report}
          idx={this.props.idx}
        />
      ) : (
        <AcceptByGLModal
          auth={this.props.auth}
          onAccept={this.props.onAccept}
          status={status}
          taskReport={this.props.taskReport}
        />
      );
    }
    return (
      (!this.props.auth.is_gl || !status) && (
        <Button
          color={color}
          size={"mini"}
          className={`accept-button ${className}`}
          disabled={
            (!this.props.auth.is_staff &&
              ["ACCEPTED", "TO_GL_ACCEPT"].includes(status_value)) ||
            (!this.props.auth.is_superuser &&
              ["ACCEPTED", "TO_GL_ACCEPT", "PRE_ACCEPT"].includes(status_value))
          }
          onClick={onClick}
        >
          <Icon name={icon} />
          {value}
        </Button>
      )
    );
  };

  getEditButton = () => {
    const taskReport = this.props.taskReport;
    if (this.state.editable) {
      return (
        <Button
          size="mini"
          disabled={
            (!this.props.auth.is_staff &&
              ["ACCEPTED", "TO_GL_ACCEPT"].includes(taskReport.status)) ||
            (!this.props.auth.is_superuser &&
              ["ACCEPTED", "TO_GL_ACCEPT", "PRE_ACCEPT"].includes(
                taskReport.status
              ))
          }
          className={"edit datahive-button"}
          color="yellow"
          onClick={this.onSaveClick}
          content={"Save"}
        />
      );
    }
    return (
      <Button
        size="mini"
        disabled={
          (!this.props.auth.is_staff &&
            ["ACCEPTED", "TO_GL_ACCEPT"].includes(taskReport.status)) ||
          (!this.props.auth.is_superuser &&
            ["ACCEPTED", "TO_GL_ACCEPT", "PRE_ACCEPT"].includes(
              taskReport.status
            ))
        }
        className={"edit datahive-button"}
        color="black"
        onClick={() => this.setState({ editable: true })}
        content={"Edit"}
      />
    );
  };

  getHours = () => {
    const { taskReport } = this.props;
    const hours_text = this.props.auth.is_staff ? (
      <span
        className={
          getHoursAcceptable(taskReport)
            ? "hours acceptable"
            : "hours nonacceptable"
        }
      >
        {secondsToHm(taskReport.hours * 3600)}
      </span>
    ) : (
      <span className="hours">{taskReport.hours.toFixed(2)}</span>
    );
    return this.state.editable ? (
      <HoursInput
        hours={this.state.hours}
        onHoursChange={this.onHoursChange}
        onEditClick={this.onEditClick}
        key={this.props.idx || taskReport.id}
      />
    ) : (
      hours_text
    );
  };

  mapActivity = (activity) => {
    return (
      <>
        <div className="activityItem">
          {"Answers / Del:"}
          <Label basic color="grey" size="small">
            {activity["answers"] + " / " + activity["deleted_answers"]}
          </Label>
        </div>
        {!!activity["reviews"] && (
          <div className="activityItem">
            {"Reviews:"}
            <Label basic color="grey" size="small">
              {activity["reviews"]}
            </Label>
          </div>
        )}
        {(!!activity["evaluation_answers"] ||
          !!activity["deleted_evaluation_answers"]) && (
          <div className="activityItem">
            {"Evals / Del:"}
            <Label basic color="grey" size="small">
              {activity["evaluation_answers"] +
                " / " +
                activity["deleted_evaluation_answers"]}
            </Label>
          </div>
        )}
      </>
    );
  };

  renderRowGroupedByUser = (taskReport, status, tempo, expectedPace) => {
    const { title, language, activity } = taskReport;
    return (
      <>
        <Grid.Row
          className={`task-report-row ${string_to_slug(taskReport.type)}`}
          style={{ paddingBottom: 0 }}
          verticalAlign="middle"
          id={`task-row-${taskReport.id}`}
          divided
        >
          <Grid.Column width="2">
            {title || ""}
            {taskReport.enable_evaluations ? (
              <Label className="evalLabel" circular color="grey" size="mini">
                eval
              </Label>
            ) : undefined}
          </Grid.Column>
          <Grid.Column width="1">{language || ""}</Grid.Column>
          <Grid.Column width="2">
            {<div>{this.mapActivity(activity)}</div>}
          </Grid.Column>
          <Grid.Column width="1" style={{ fontSize: "13px" }}>
            {secondsToHm(taskReport.sessions_duration)}
          </Grid.Column>
          <Grid.Column width="1" style={{ fontSize: "13px" }}>
            {secondsToHm(taskReport.total_time_taken)}
          </Grid.Column>
          <Grid.Column width="1" style={{ fontSize: "13px", padding: "0px" }}>
            {!this.state.editable ? (
              <ReportHistoryPopup
                report={this.props.report}
                config={config.LINGUISTS_TASK_REPORT_HISTORY}
                hours={this.getHours()}
                reportId={taskReport.id}
              />
            ) : (
              this.getHours()
            )}
          </Grid.Column>
          <Grid.Column width="1">{tempo}</Grid.Column>
          <Grid.Column width="1">{expectedPace || "-"}</Grid.Column>
          <Grid.Column width="2">
            <ReportAcceptedPopup
              report={taskReport}
              config={config.LINGUISTS_TASK_REPORT_HISTORY}
            />
          </Grid.Column>
          <Grid.Column width="4" style={{ minHeight: "24px" }}>
            {this.renderButton(status, taskReport.status)}
            {!this.props.auth.is_gl && this.props.auth.is_superuser && (
              <SendToGLModal
                onSendToGL={this.props.onSendToGL}
                status={status}
                taskReport={this.props.report}
                idx={this.props.idx}
              />
            )}
            {!this.props.auth.is_gl && this.getEditButton()}
          </Grid.Column>
        </Grid.Row>
        <SessionsSummary taskReportId={taskReport.id} />
      </>
    );
  };

  renderUserNativity = (nativityStatus) => {
    switch (nativityStatus) {
      case "NATIVE":
        return <Icon name="flag" className="native-icon" />;
      case "NON-NATIVE":
        return <Icon name="flag outline" className="non-native-icon" />;
      default:
        return <span>N/A</span>;
    }
  };

  renderRowGroupedByTask = (taskReport, status, tempo, expectedPace) => {
    return (
      <>
        <Grid.Row
          verticalAlign="middle"
          style={{ paddingBottom: 0 }}
          divided
          className={"task-report-row"}
          id={`task-row-${taskReport.id}`}
        >
          <Grid.Column
            width={2}
            style={{ fontSize: "11px", overflowWrap: "anywhere" }}
          >
            <Link to={`/user/profile/${taskReport.user.username}`}>
              {taskReport.user.username}
            </Link>
          </Grid.Column>
          <Grid.Column width={1} style={{ fontSize: "11px", padding: 0 }}>
            {taskReport.user.company?.name}
          </Grid.Column>
          <Grid.Column width={1}>
            {this.renderUserNativity(taskReport.user_nativity)}
          </Grid.Column>
          {this.props.allTime && (
            <Grid.Column width={2}>{taskReport.deadline_name}</Grid.Column>
          )}
          <Grid.Column width={2}>
            {this.mapActivity(taskReport.activity)}
          </Grid.Column>
          <Grid.Column width={1} style={{ fontSize: "13px" }}>
            {secondsToHm(taskReport.sessions_duration)}
          </Grid.Column>
          <Grid.Column width={1} style={{ fontSize: "13px" }}>
            {secondsToHm(taskReport.total_time_taken)}
          </Grid.Column>
          <Grid.Column width={1} style={{ fontSize: "13px", padding: "0px" }}>
            {!this.state.editable ? (
              <ReportHistoryPopup
                report={taskReport}
                config={config.LINGUISTS_TASK_REPORT_HISTORY}
                reportId={taskReport.id}
                hours={this.getHours()}
              />
            ) : (
              this.getHours()
            )}
            {taskReport.description && !this.state.editable && (
              <Popup
                flowing
                position={"top center"}
                content={
                  <>
                    <div>Description:</div>
                    <div>{taskReport.description}</div>
                  </>
                }
                trigger={
                  <Icon
                    style={{ display: "inline", marginLeft: "4px" }}
                    name={"info circle"}
                    color="grey"
                  />
                }
              />
            )}
          </Grid.Column>
          <Grid.Column width={1}>{tempo}</Grid.Column>
          <Grid.Column width={1}>{expectedPace || "-"}</Grid.Column>
          <Grid.Column width={2}>
            <ReportAcceptedPopup
              report={taskReport}
              config={config.LINGUISTS_TASK_REPORT_HISTORY}
            />
          </Grid.Column>
          <Grid.Column width={3} style={{ minHeight: "24px" }}>
            {this.renderButton(status, taskReport.status)}
            {!this.props.auth.is_gl && this.props.auth.is_superuser && (
              <SendToGLModal
                onSendToGL={this.props.onSendToGL}
                status={status}
                taskReport={this.props.taskReport}
              />
            )}
            {!this.props.auth.is_gl && this.getEditButton()}
          </Grid.Column>
        </Grid.Row>
        <SessionsSummary taskReportId={taskReport.id} />
      </>
    );
  };

  render() {
    const {
      taskReport,
      auth: { is_staff },
    } = this.props;
    const status = is_staff
      ? _.includes(
          [
            TASK_MONTH_REPORT_STATUS_NOT_ACCEPTED,
            TASK_MONTH_REPORT_STATUS_PRE_ACCEPT,
            TASK_MONTH_REPORT_STATUS_TO_GL_ACCEPT,
          ],
          taskReport.status
        )
      : taskReport.status === TASK_MONTH_REPORT_STATUS_NOT_ACCEPTED;

    const tempo = taskReport.hours
      ? (taskReport.activity.answers / taskReport.hours).toFixed(2)
      : taskReport.sessions_duration && taskReport.activity.answers
      ? (
          (taskReport.activity.answers / taskReport.sessions_duration) *
          3600
        ).toFixed(2)
      : "-";

    let row;
    switch (this.props.groupedBy) {
      case GROUPED_BY.USER:
        row = this.renderRowGroupedByUser(
          taskReport,
          status,
          tempo,
          taskReport.expected_pace
        );
        break;
      default:
        row = this.renderRowGroupedByTask(
          taskReport,
          status,
          tempo,
          taskReport.expected_pace
        );
        break;
    }

    return row;
  }
}

export default withRouter(TaskReportRow);
