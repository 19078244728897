import _ from "lodash";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Container, Label, Message } from "semantic-ui-react";
import ProgressBar from "../../components/ProgressBar";
import config from "../../config/config";
import LoaderBlocker from "../../components/LoaderBlocker";
import ajax from "../../helpers/ajax";
import TaskTutorial from "./Tabs/TaskTutorial";
import TaskFAQ from "./Tabs/TaskFAQ";
import TaskNew from "./Tabs/TaskNew";
import TaskDetails from "./Tabs/TaskDetails";
import TaskDone from "./Tabs/TaskDone";
import TaskEvaluationNew from "./Tabs/TaskEvaluationNew";
import DescriptionContainer from "./DescriptionContainer";
import { connect } from "react-redux";
import { toastr } from "react-redux-toastr";
import moment from "moment";
import urls from "../../config/frontend_urls";

import Tabs from "../../components/Tabs";
import PropTypes from "prop-types";
import TaskQuestions from "./Tabs/TaskQuestions";
import TaskEvaluationDone from "./Tabs/TaskEvaluationDone";

import "./Task.css";
import Timer from "./Timer/Timer";

export class Task extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    isStaff: PropTypes.bool,
    isSuperuser: PropTypes.bool,
    hasUserDataAccess: PropTypes.bool,
    isExternal: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.state = {
      task: {
        // faq: {},
        // tutorial: {},
        // config: {},
        // title: '',
        // description: ''
        // confirmed: false
      },
      question: {},
      show_data: "",
      savedFilters: {},
      allQuestionsAnswered: false,
      allEvaluationQuestionsAnswered: false,
      userAnsweringNow: false,
      isReachedLimit: false,
    };

    this.progressBar = React.createRef();
  }

  componentDidMount() {
    this.getTask();
  }

  getTask = () => {
    ajax
      .get([config.TASK_DETAIL, this.props.match.params.taskSlug])
      .then((response) => {
        this.setState({ task: response.data }, this.tutorialDisplayTime);
      })
      .catch((error) => {
        error = "nope";
        this.setState({
          not_found: _.get(error, "response.statusText") || "Unknown problem",
        });
      });
  };

  tutorialDisplayTime = () => {
    const _state = this.state;
    ajax
      .get([
        config.TASK_TUTORIAL_DISPLAY_TIME,
        this.props.match.params.taskSlug,
      ])
      .then((response) => {
        const newState = {};
        if (
          !!response.data.is_user_tutorial_changed &&
          !_state.isUserTutorialChanged
        ) {
          newState.isUserTutorialChanged =
            response.data.is_user_tutorial_changed;
        }
        if (
          !!response.data.is_eval_tutorial_changed &&
          !_state.isEvalTutorialChanged
        ) {
          newState.isEvalTutorialChanged =
            response.data.is_eval_tutorial_changed;
        }
        this.setState(newState);
      })
      .catch(() => { });
  };

  tutorialDisplayTimeAck = (forEvaluators = false, isFaq = false) => {
    const update = `?update=1&for_evaluators=${forEvaluators}&is_faq=${isFaq}`;
    ajax
      .get([
        config.TASK_TUTORIAL_DISPLAY_TIME + update,
        this.props.match.params.taskSlug,
      ])
      .then(() => {
        const key = forEvaluators
          ? "isEvalTutorialChanged"
          : "isUserTutorialChanged";
        this.setState({ [key]: false });
      });
  };

  saveFiltersDone = (filters) => {
    this.setState({ savedFiltersDone: filters });
  };

  saveFiltersQuestions = (filters) => {
    this.setState({ savedFiltersQuestions: filters });
  };

  onTutorialAccepted = (tutorial, forEvaluators = false) => {
    // change task state to confirmed and redirect to new tab
    const task = _.cloneDeep(this.state.task);
    let url;
    if (forEvaluators) {
      task.evaluation_tutorial_confirmed.push({
        wiki: tutorial.id,
        is_confirmed: true,
      });
      const requiredTutorialIds = task.evaluation_tutorial
        .filter((item) => item.is_required === true)
        .map((item) => item.id);
      const confirmedTutorialIds = task.evaluation_tutorial_confirmed.map(
        (item) => item.wiki
      );
      const isAllConfirmed = requiredTutorialIds.every((item) =>
        confirmedTutorialIds.includes(item)
      );
      if (isAllConfirmed) {
        task.evaluation_confirmed = true;
        url = urls.TASK_TAB.replace(":taskSlug", task.slug).replace(
          ":subTab",
          "evaluation"
        );
      }
    } else {
      task.tutorial_confirmed.push({ wiki: tutorial.id, is_confirmed: true });
      const requiredTutorialIds = task.tutorial
        .filter((item) => item.is_required === true)
        .map((item) => item.id);
      const confirmedTutorialIds = task.tutorial_confirmed.map(
        (item) => item.wiki
      );
      const isAllConfirmed = requiredTutorialIds.every((item) =>
        confirmedTutorialIds.includes(item)
      );
      if (isAllConfirmed) {
        task.confirmed = true;
        url = urls.TASK.replace(":taskSlug", task.slug);
      }
    }
    this.setState({ task }, () => this.props.history.push(url));
  };

  onIncreaseAnswered = () => {
    if (!this.state.userAnsweringNow) {
      const isReachedLimit =
        this.progressBar.current.state.userAnswers ===
        this.state.task.max_questions;
      this.setState({ isReachedLimit, userAnsweringNow: true });
    }
    this.progressBar.current.increaseAnswered();

    // Disable resign button after first answer.
    if (this.state.task.can_resign) {
      const task = _.cloneDeep(this.state.task);
      task.can_resign = false;
      this.setState({ task });
    }
  };

  onIncreaseEvaluated = () => {
    this.progressBar.current.increaseEvaluated();
  };

  onTaskResigned = () => {
    // delete task and redirect to task list
    if (this.props.history.location.pathname !== urls.TASKS_LIST) {
      this.props.history.push(urls.TASKS_LIST);
    }
  };

  onLastQuestionAnswered = () => {
    this.setState({ allQuestionsAnswered: true });
  };

  onLastEvaluationQuestionAnswered = () => {
    this.setState({ allEvaluationQuestionsAnswered: true });
  };

  updateFavorites = (slug, isFavorite, pinColor) => {
    const text = !isFavorite ? "added to" : "removed from";
    ajax
      .post([config.TASKS_UPDATE_FAVORITES, slug, pinColor])
      .then(() => {
        this.setState((prevState) => ({
          task: {
            ...prevState.task,
            is_favorite: !isFavorite,
            favorites_pin_color: pinColor,
          },
        }));
        toastr.success("Success!", `Task ${text} favorites!`);
      })
      .catch((errors) => {
        toastr.error("Error!", this.getErrorText(errors));
      });
  };

  render() {
    if (this.state.not_found) {
      return (
        <Container textAlign="center">
          <Message info className="datahive-message" header="Information">
            <p>
              Task <b>{this.props.match.params.taskSlug}</b> is probably not
              available.
            </p>
            <p>Please contact with your coordinator.</p>
          </Message>
        </Container>
      );
    }
    if (!this.state.task || !this.state.task.title) {
      return <LoaderBlocker show={true} />;
    }

    const locale = window.navigator.userLanguage || window.navigator.language;
    moment.locale(locale);
    const { task } = this.state;
    const slug = task.slug || "";
    const description = task.description || "";
    const is_favorite = task.is_favorite || false;
    const favorites_pin_colors = [
      "db244d",
      "f85e00",
      "fa9500",
      "fbbd08",
      "a5be00",
      "2e933c",
      "028090",
      "1d4e89",
      "6f2dbd",
      "000000",
    ];
    const favorites_pin_color = task.favorites_pin_color;
    const reviewable = task.possible_review;
    const progressBar = this.progressBar;
    const confirmed = task.confirmed || false;
    const evaluation_confirmed = task.evaluation_confirmed || false;
    const questionsAtPage = task.questions_at_page || 1;
    const deadline = task.deadline ? moment(task.deadline) : null;
    const evaluationDeadline = task.evaluation_deadline
      ? moment(task.evaluation_deadline)
      : null;
    const { allEvaluationQuestionsAnswered, allQuestionsAnswered } = this.state;
    const isModerator = task.user_is_moderator || this.props.isSuperuser;
    const isEvaluator = task.user_is_evaluator;
    const numberOfQuestions = progressBar.current;

    const taskQuestions = (
      <TaskQuestions
        config={task.config}
        slug={slug}
        isModerator={isModerator}
        isCompanyCoordinator={task.user_is_company_coordinator}
        questionsAtPage={questionsAtPage}
        saveFilters={this.saveFiltersQuestions}
        filters={this.state.savedFiltersQuestions}
      />
    );

    const taskNew = (
      <TaskNew
        config={task.config}
        slug={slug}
        onIncreaseAnswered={this.onIncreaseAnswered}
        onTutorialDisplayTime={this.tutorialDisplayTime}
        isModerator={isModerator}
        confirmed={confirmed}
        allConfirmed={task.tutorial_confirmed}
        blocked={task.blocked}
        allQuestionsAnswered={allQuestionsAnswered}
        onLastQuestionAnswered={this.onLastQuestionAnswered}
        reviewable={reviewable}
        canLinguistStartDiscussion={task.can_linguist_start_discussion}
      />
    );

    const taskDone = (
      <TaskDone
        config={task.config}
        slug={slug}
        reviewable={reviewable}
        isModerator={isModerator}
        isCompanyCoordinator={task.user_is_company_coordinator}
        questionsAtPage={questionsAtPage}
        saveFilters={this.saveFiltersDone}
        filters={this.state.savedFiltersDone}
        canLinguistStartDiscussion={task.can_linguist_start_discussion}
      />
    );

    const propsTaskEvaluationDone = {
      config: task.config,
      config_evaluation: task.config_evaluation,
      slug: slug,
      onIncreaseAnswered: this.onIncreaseEvaluated,
      onTutorialDisplayTime: this.tutorialDisplayTime,
      isModerator: isModerator,
      confirmed: evaluation_confirmed,
      blocked: task.blocked,
      questionsAtPage: questionsAtPage,
      saveFilters: this.saveFiltersDone,
    };

    let taskEvaluationNew;
    if (!this.state.task.enable_search_evaluations) {
      taskEvaluationNew = (
        <TaskEvaluationNew
          config={task.config}
          config_evaluation={task.config_evaluation}
          onIncreaseAnswered={this.onIncreaseEvaluated}
          slug={slug}
          confirmed={evaluation_confirmed}
          isModerator={isModerator}
          onTutorialDisplayTime={this.tutorialDisplayTime}
          isCompanyCoordinator={task.user_is_company_coordinator}
          questionsAtPage={questionsAtPage}
          saveFilters={this.saveFiltersDone}
          filters={this.state.savedFiltersDone}
          isNew={true}
          allQuestionsAnswered={allEvaluationQuestionsAnswered}
          onLastQuestionAnswered={this.onLastEvaluationQuestionAnswered}
        />
      );
    } else {
      taskEvaluationNew = (
        <TaskEvaluationDone
          {...propsTaskEvaluationDone}
          isNew={true}
          enable_search_evaluations={task.enable_search_evaluations}
        />
      );
    }

    const taskEvaluationDone = // eslint-disable-line no-unused-vars
      (
        <TaskEvaluationDone
          config={task.config}
          config_evaluation={task.config_evaluation}
          slug={slug}
          onIncreaseAnswered={this.onIncreaseEvaluated}
          onTutorialDisplayTime={this.tutorialDisplayTime}
          isModerator={isModerator}
          confirmed={evaluation_confirmed}
          blocked={task.blocked}
          questionsAtPage={questionsAtPage}
          saveFilters={this.saveFiltersDone}
          isNew={false}
        />
      );

    const panes = [];
    if (task.user_is_assigned || isModerator) {
      panes.push(
        ...[
          {
            key: "new",
            label: {
              name: "New",
              icon: "file text outline",
            },
            components: taskNew,
          },
          {
            key: "done",
            label: {
              name: "Done",
              icon: "check square",
            },
            components: taskDone,
          },
        ]
      );
    }
    if (task.tutorial.length && (task.user_is_assigned || isModerator)) {
      const taskTutorial = (
        <TaskTutorial
          selectedTutorials={this.state.selectedTutorials}
          tutorials={task.tutorial}
          slug={slug}
          confirmed={task.confirmed}
          allConfirmed={task.tutorial_confirmed}
          onConfirmed={this.onTutorialAccepted}
          tutorialDisplayTimeAck={() =>
            this.tutorialDisplayTimeAck(false, false)
          }
        />
      );
      let label = "Tutorial";
      if (!this.state.task.confirmed) {
        label = (
          <>
            Tutorial
            <Label color="red">!</Label>
          </>
        );
      }
      panes.push({
        key: "tutorial",
        label: {
          name: label,
          icon: "life ring",
        },
        components: taskTutorial,
      });
    }
    if (task.faq.length && (isModerator || task.user_is_assigned)) {
      const taskFAQ = (
        <TaskFAQ
          faqs={task.faq}
          tutorialDisplayTimeAck={() => {
            isModerator && this.tutorialDisplayTimeAck(true, true);
            (task.user_is_assigned || isModerator) &&
              this.tutorialDisplayTimeAck(false, true);
          }}
        />
      );

      panes.push({
        key: "faq",
        label: {
          name: "FAQ",
          icon: "question circle",
        },
        components: taskFAQ,
      });
    }

    if (task.enable_evaluations && (isEvaluator || isModerator)) {
      panes.push(
        {
          key: "evaluation",
          label: {
            name: "Evaluation",
            icon: "eye",
          },
          components: taskEvaluationNew,
        },
        {
          key: "evaluation-done",
          label: {
            name: "Evaluation Done",
            icon: "eye",
          },
          components: taskEvaluationDone,
        }
      );
    }

    if (isModerator) {
      panes.unshift({
        key: "questions",
        label: {
          name: "Questions",
          icon: "archive",
        },
        components: taskQuestions,
      });
    }

    if (task.evaluation_tutorial.length && (isEvaluator || isModerator)) {
      const taskTutorial = (
        <TaskTutorial
          tutorials={task.evaluation_tutorial}
          slug={slug}
          allConfirmed={task.evaluation_tutorial_confirmed}
          onConfirmed={this.onTutorialAccepted}
          tutorialDisplayTimeAck={() =>
            this.tutorialDisplayTimeAck(true, false)
          }
          forEvaluators={true}
        />
      );
      let label = "Evaluation Tutorial";
      if (!!this.state.isEvalTutorialChanged) {
        label = (
          <>
            Evaluation Tutorial
            <Label color="red">!</Label>
          </>
        );
      }
      panes.push({
        key: "evaluation_tutorial",
        label: {
          name: label,
          icon: "life ring",
        },
        components: taskTutorial,
      });
    }
    if (task.evaluation_faq.length && (isModerator || isEvaluator)) {
      const taskFAQ = (
        <TaskFAQ
          faqs={task.evaluation_faq}
          tutorialDisplayTimeAck={() => {
            isModerator && this.tutorialDisplayTimeAck(true, true);
            (isEvaluator || isModerator) &&
              this.tutorialDisplayTimeAck(false, true);
          }}
        />
      );

      panes.push({
        key: "evaluation_faq",
        label: {
          name: "Evaluation FAQ",
          icon: "question circle",
        },
        components: taskFAQ,
      });
    }

    const taskConfigStructure = this.state.task.config.structure;
    let widgetReportType = "";
    if (
      taskConfigStructure.filter((e) => e.componentType === "AudioAnswerWidget")
        .length > 0
    ) {
      widgetReportType = "AudioAnswerWidget";
    } else if (
      taskConfigStructure.filter(
        (e) => e.componentType === "MediaWaveformWidget" && e.isVideo
      ).length > 0
    ) {
      widgetReportType = "VideoWidget";
    }
    const taskDetails = (
      <TaskDetails slug={slug} widgetReportType={widgetReportType} />
    );
    panes.push({
      key: "details",
      label: {
        name: "Details",
        icon: "users",
      },
      components: taskDetails,
    });

    // generate url props for tabs component
    const taskUrl = urls.TASK_TAB.replace(
      ":taskSlug",
      this.props.match.params.taskSlug
    );
    // push tab specific urls to panes array
    panes.forEach((pane) => {
      pane.url = taskUrl.replace(":subTab", "");
      pane.tabUrl = taskUrl.replace(":subTab", pane.key);
    });

    let extraOptions =
      this.props.isStaff || _.get(this.state, "task.user_is_moderator", false);
    extraOptions = this.props.hasUserDataAccess ? extraOptions : false;

    return (
      <div className={"ui grid TaskDetail"}>
        <div className={"twelve wide column centered"}>
          <Timer taskId={task.pk} taskTitle={task.title} />
          <DescriptionContainer
            extraOptions={extraOptions}
            title={task.title}
            slug={slug}
            description={description}
            deadline={deadline}
            is_favorite={is_favorite}
            favorites_pin_colors={favorites_pin_colors}
            favorites_pin_color={favorites_pin_color}
            updateFavorites={this.updateFavorites}
            enable_evaluations={task.enable_evaluations}
            evaluationDeadline={evaluationDeadline}
            maxQuestions={task.max_questions}
            evaluationMaxQuestions={task.evaluation_max_questions}
            onResigned={this.onTaskResigned}
            allQuestionsAnswered={allQuestionsAnswered}
            allEvaluationQuestionsAnswered={allEvaluationQuestionsAnswered}
            canResign={task.can_resign}
            numberOfQuestions={numberOfQuestions}
            canSeeReminder={this.props.isExternal}
            userAnsweringNow={this.state.userAnsweringNow}
            isReachedLimit={this.state.isReachedLimit}
          />
          <Container></Container>
          <ProgressBar taskSlug={slug} ref={progressBar} />
          <Tabs panes={panes} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isExternal: state.auth.is_external,
    isStaff: state.auth.is_staff,
    isSuperuser: state.auth.is_superuser,
    hasUserDataAccess: state.auth.has_user_data_access,
  };
};
export default connect(mapStateToProps)(withRouter(Task));
