import React, { useEffect, useState } from "react";
import { Button, Icon, Modal, ModalActions, ModalContent, ModalHeader, Select } from "semantic-ui-react";
import TimeLogsModal from "./TimeLogsModal";
import "./Timer.css";
import ajax from "../../../helpers/ajax";
import config from "../../../config/config";
import { toastr } from "react-redux-toastr";
import PropTypes from "prop-types";
import _ from "lodash";
import moment from "moment";

export const formatDuration = (time) => {
  const seconds = Math.floor(time);
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;
  return `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}:${remainingSeconds.toString().padStart(2, "0")}`;
};

export const formatDate = (date) => {
  const timezone = moment.tz.guess();
  return moment.tz(date, timezone).format("DD-MM-YYYY");
};

export const categoryOptions = [
  { key: "Tutorial", text: "Tutorial", value: "TUTORIAL" },
  { key: "Task", text: "Task", value: "TASK" },
  { key: "Test", text: "Test", value: "TEST" },
  { key: "Verification", text: "Verification", value: "VERIFICATION" },
  { key: "Paid corrections", text: "Paid corrections", value: "PAID_CORRECTIONS" },
  { key: "Out of platform", text: "Out of platform", value: "OUT_OF_PLATFORM" },
  { key: "Operation", text: "Operation", value: "OPERATION" },
];

const Timer = ({ taskId, taskTitle }) => {

  const [isRunning, setIsRunning] = useState(false);
  const [time, setTime] = useState(0);
  const [category, setCategory] = useState("");
  const [sessions, setSessions] = useState([]);
  const [summary, setSummary] = useState({});
  const [currentSession, setCurrentSession] = useState(null);
  const [isListModalOpen, setIsListModalOpen] = useState(false);
  const [isStartModalOpen, setIsStartModalOpen] = useState(false);

  const getSessions = () => {
    ajax.get([config.SESSIONS, taskId])
      .then((response) => {
        setSessions(response.data.sessions);
        setSummary(response.data.summary);
      })
      .catch((errors) => {
        const err = JSON.stringify(_.get(errors, "response.data", "Something went wrong!"));
        toastr.error("Error!", err);
      });
  };

  const getActiveSession = () => {

    ajax.get([config.ONGOING_SESSION, taskId])
      .then((response) => {
        if (response.data) {
          setCurrentSession(response.data);
          if (response.data.task.id === taskId) {
            setCategory(response.data.category);
            setIsRunning(true);
            setTime(response.data.current_duration);
          }
        }
      })
      .catch((errors) => {
        if (!errors.response.status === 404) {
          const err = JSON.stringify(_.get(errors, "response.data", "Something went wrong!"));
          toastr.error("Error!", err);
        }
      });
  };

  const startNewSession = () => {
    const data = {
      task: taskId,
      category: category
    };

    ajax.post([config.START_SESSION], { data })
      .then((response) => {
        setCurrentSession(response.data);
        setIsRunning(true);
      })
      .catch((errors) => {
        const err = JSON.stringify(_.get(errors, "response.data", "Something went wrong!"));
        toastr.error("Error!", err);
      });
  };

  const endSession = () => {
    const data = {
      session: currentSession.id
    };

    ajax.post([config.STOP_SESSION], { data })
      .then(() => {
        setCurrentSession(null);
      })
      .catch((errors) => {
        if (errors.response.status === 400) {
          setCategory("");
          setIsRunning(false);
          setTime(0);
        }
        const err = JSON.stringify(_.get(errors, "response.data", "Something went wrong!"));
        toastr.error("Error!", err);
      });
  };

  const handleStartTimer = () => {
    if (currentSession && currentSession.task.id !== taskId) {
      setIsStartModalOpen(true);
    } else {
      startNewSession();
    }
  };

  const handleStopTimer = () => {
    endSession();
    setIsRunning(false);
    setCategory("");
    setTime(0);
  };

  const handleConfirm = () => {
    startNewSession();
    setIsStartModalOpen(false);
  };

  useEffect(() => {
    let intervalId;
    if (isRunning) {
      intervalId = setInterval(() => {
        setTime((prevTime) => prevTime + 1);
      }, 1000);
    }
    return () => clearInterval(intervalId);
  }, [isRunning]);

  useEffect(() => {
    getActiveSession();

    const interval = setInterval(() => {
      getActiveSession();
    }, 60000);

    return () => clearInterval(interval);
  }, []);


  useEffect(() => {
    if (isListModalOpen) {
      getSessions();
    } else {
      setSessions([]);
    }
  }, [isListModalOpen]);

  return (
    <div className="timer-container">
      <div className="timer-content">
        <div className="timer-group">
          {!isRunning ? (
            <Select
              placeholder="Select category"
              value={category}
              onChange={(e, { value }) => setCategory(value)}
              options={categoryOptions}
            />
          ) : (
            <div className="category-container">
              <h4>{category}</h4>
            </div>

          )}

          {!isRunning ? (
            <Button disabled={category ? false : true} positive icon onClick={handleStartTimer}>
              <Icon name="play" />
            </Button>
          ) : (
            <Button negative icon onClick={handleStopTimer}>
              <Icon name="stop" />
            </Button>
          )}
          <h3 className="timer-display">{formatDuration(time)}</h3>
        </div>
        <Button icon onClick={() => setIsListModalOpen(true)}>
          <Icon name="list" />
        </Button>
      </div>
      {isListModalOpen && (
        <TimeLogsModal
          getSessions={getSessions}
          sessions={sessions}
          summary={summary}
          setIsListModalOpen={setIsListModalOpen}
          isListModalOpen={isListModalOpen}
          taskTitle={taskTitle} />
      )}
      {isStartModalOpen && (
        <Modal
          closeIcon
          onClose={() => setIsStartModalOpen(false)}
          onOpen={() => setIsStartModalOpen(true)}
          open={isStartModalOpen}
        >
          <ModalHeader>
            Warning
          </ModalHeader>
          <ModalContent >
            {`Other session is in progress. Do you want to end session on  task ${currentSession?.task.title}  and start new session?`}
          </ModalContent>
          <ModalActions>
            <Button
              onClick={() => setIsStartModalOpen(false)}
              negative
            >
              No
            </Button>
            <Button
              onClick={() => handleConfirm()}
              positive
            >
              Yes
            </Button>
          </ModalActions>
        </Modal>
      )}
    </div>
  );
};

Timer.propTypes = {
  taskId: PropTypes.string.isRequired,
  taskTitle: PropTypes.string.isRequired,
};

export default Timer;
