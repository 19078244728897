import React from "react";
import { Modal, ModalHeader, ModalContent } from "semantic-ui-react";
import "./TimeLogsModal.css";
import PropTypes from "prop-types";
import SessionsTable from "./SessionsTable";

const TimeLogsModal = ({ sessions, getSessions, summary, setIsListModalOpen, isListModalOpen, taskTitle }) => {

  return (
    <Modal
      closeIcon
      onClose={() => setIsListModalOpen(false)}
      onOpen={() => setIsListModalOpen(true)}
      open={isListModalOpen}
      size="fullscreen"
    >
      <ModalHeader>
        {taskTitle} {" sessions"}
      </ModalHeader>
      <ModalContent scrolling>
        <SessionsTable sessions={sessions} summary={summary} getSessions={getSessions} />
      </ModalContent>
    </Modal>
  );
};

TimeLogsModal.propTypes = {
  sessions: PropTypes.arrayOf(PropTypes.object).isRequired,
  getSessions: PropTypes.func.isRequired,
  summary: PropTypes.object.isRequired,
  taskTitle: PropTypes.string.isRequired,
  logs: PropTypes.arrayOf(PropTypes.object),
  setIsListModalOpen: PropTypes.func.isRequired,
  isListModalOpen: PropTypes.bool.isRequired
};

export default TimeLogsModal;
