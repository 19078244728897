import _ from "lodash";

import React, {Component} from "react";
import {Button, Grid, Icon, Label} from "semantic-ui-react";

import config from "../../config/config";
import {
  LINGUIST_MONTH_REPORT_STATUS_PENDING,
  LINGUIST_MONTH_REPORT_STATUS_PRE_ACCEPT
} from "../../constraints/Ele";
import {ReportHistoryPopup, ReportAcceptedPopup} from "./ReportHistoryPopup";
import PropTypes from "prop-types";
import {GROUPED_BY, secondsToHm} from "./utils";


export default class ReportTitleRow extends Component {
  static propTypes = {
    auth: PropTypes.shape({
      is_superuser: PropTypes.bool,
      is_staff: PropTypes.bool,
      is_gl: PropTypes.bool,
    }),
    report: PropTypes.oneOfType([
      PropTypes.shape({
        user: PropTypes.shape({
          username: PropTypes.string,
          type: PropTypes.string,
          company: PropTypes.shape({
            name: PropTypes.string
          })
        }),
        deadlineName: PropTypes.string,
        id: PropTypes.number,
        status: PropTypes.string,
        total_hours: PropTypes.number,
        total_timer_hours: PropTypes.number,
        total_auto_hours: PropTypes.number,
        tasks_reports: PropTypes.array,
      }),
      PropTypes.shape({
        task: PropTypes.shape({
          id: PropTypes.number.isRequired,
          slug: PropTypes.string.isRequired,
          language: PropTypes.string.isRequired,
          enable_evaluations: PropTypes.bool.isRequired
        }),
        reports: PropTypes.arrayOf(PropTypes.object).isRequired,
        deadline: PropTypes.shape({
          name: PropTypes.string.isRequired
        }),
        sum: PropTypes.number
      }),
      PropTypes.shape({
        project: PropTypes.shape({
          id: PropTypes.number.isRequired,
          name: PropTypes.string.isRequired
        }),
        reports: PropTypes.arrayOf(PropTypes.object).isRequired,
        deadline: PropTypes.shape({
          name: PropTypes.string.isRequired
        }),
        sum: PropTypes.number
      })
    ]),
    groupedBy: PropTypes.string,
    onClick: PropTypes.func.isRequired,
    active: PropTypes.bool,
    idx: PropTypes.number,
    onUserAccept: PropTypes.func,
    nested: PropTypes.bool
  };

  createButton = (statusValue) => {
    if (this.props.groupedBy !== GROUPED_BY.USER) {
      return null;
    }

    let status = null;

    if (this.props.auth.is_staff) {
      status = _.includes([LINGUIST_MONTH_REPORT_STATUS_PENDING, LINGUIST_MONTH_REPORT_STATUS_PRE_ACCEPT], this.props.report.status);
    } else {
      status = this.props.report.status === LINGUIST_MONTH_REPORT_STATUS_PENDING;
    }

    const superuser = (this.props.auth.is_superuser);
    let className, color, value, icon;
    if (status) {
      className = "datahive-button";
      color = "green";
      icon = "checkmark box";
      value = superuser ? "Accept" : "Pre-Accept";
    } else {
      className = "datahive-button";
      color = "red";
      value = "Reject";
      icon = "window close outline";
    }
    return (
      <Button
        color={color}
        size={"small"}
        className={`accept-button ${className}`}
        disabled={(!this.props.auth.is_staff && ["ACCEPTED", "TO_GL_ACCEPT"].includes(statusValue)) || (!this.props.auth.is_superuser && ["ACCEPTED", "TO_GL_ACCEPT", "PRE_ACCEPT"].includes(statusValue))}
        onClick={(event) => this.props.onUserAccept(event, this.props.report, status)}
      >
        <Icon name={icon}/>
        {value}
      </Button>
    );
  };

  getStatus = () => {
    if (this.props.groupedBy === GROUPED_BY.USER) {
      return this.props.report.status;
    }

    let statuses = [];
    for (const report of this.props.report.reports) {
      if (report.reports) {
        statuses = statuses.concat(report.reports.map((taskReport) => taskReport.status));
      } else {
        statuses.push(report.status);
      }
    }

    let status;

    if (_.some(["ACCEPTED", "MANUALLY_ACCEPTED"], (el) => _.includes(statuses, el))) {
      status = "ACCEPTED";
      if (_.some(["NOT_ACCEPTED", "PRE_ACCEPT"], (el) => _.includes(statuses, el))) {
        status = "PENDING";
      }
      if (_.some(["TO_GL_ACCEPT"], (el) => _.includes(statuses, el))) {
        status = "TO_GL_ACCEPT";
      }
    }
    else if (_.some(["TO_GL_ACCEPT"], (el) => _.includes(statuses, el))) {
      status = "TO_GL_ACCEPT";
    }
    else {
      status = "NOT_ACCEPTED";
    }

    return status;
  };

  renderRowGroupedByUser = (button, hours, totalAutoHours, totalTimerHours) => {
    let ids = [];
    if (this.props.report.status === "PRE_ACCEPT" || this.props.report.status === "ACCEPTED") {
      if (this.props.report?.tasks_reports.length > 0) {
        this.props.report.tasks_reports.map((item) => ids.push(item.id));
      }
    }

    return (<>
      <Grid.Column width={2}>
        {this.props.report.user.username}
      </Grid.Column>
      <Grid.Column width={2}>
        {this.props.report.user.type}
      </Grid.Column>
      <Grid.Column width={2}>
        {this.props.report.user.company ? this.props.report.user.company.name : ""}
      </Grid.Column>
      <Grid.Column width={2}>
        {this.props.report.deadlineName}
      </Grid.Column>
      <Grid.Column width={1} style={{fontSize: "13px"}}>
        {totalTimerHours}
      </Grid.Column>
      <Grid.Column width={1} style={{fontSize: "13px"}}>
        {totalAutoHours}
      </Grid.Column>
      <Grid.Column width={1} style={{fontSize: "13px"}}>
        <ReportHistoryPopup
          report={this.props.report}
          reportId={this.props.report.id}
          config={config.LINGUISTS_MONTH_REPORT_HISTORY}
          hours={secondsToHm(hours*3600)}
        />
      </Grid.Column>
      <Grid.Column width={2}>
        <ReportAcceptedPopup
          report={this.props.report}
          config={config.LINGUISTS_TASK_MONTH_REPORT_HISTORY}
          ids={ids}
        />
      </Grid.Column>
      <Grid.Column width={3}>
        <div>{button}</div>
      </Grid.Column>
    </>);
  }

  renderRowGroupedByTask = (status) => {
    let ids = [];
    if (status === "PRE_ACCEPT" || status === "ACCEPTED") {
      if (this.props.report.reports.length > 0) {
        this.props.report.reports.map((item) => ids.push(item.id));
      }
    }

    return (<>
      <Grid.Column width={4}>
        {this.props.report.task.slug}
        {this.props.report.task.enable_evaluations ? <Label className="evalLabel" circular color="grey" size="tiny">eval</Label> : undefined}
      </Grid.Column>
      <Grid.Column width={2}>
        {this.props.report.task.language}
      </Grid.Column>
      <Grid.Column width={2}>
        {this.props.report.deadline.name}
      </Grid.Column>
      <Grid.Column width={1}>
        {secondsToHm(this.props.report.total_timer_hours)}
      </Grid.Column>
      <Grid.Column width={1}>
        {secondsToHm(this.props.report.total_auto_hours)}
      </Grid.Column>
      <Grid.Column width={1}>
        <span className="hours">{secondsToHm(this.props.report.sum * 3600)}</span>
      </Grid.Column>
      <Grid.Column width={3}>
        <ReportAcceptedPopup
          report={this.props.report}
          config={config.LINGUISTS_TASK_MONTH_REPORT_HISTORY}
          ids={ids}
          status={status}
        />
      </Grid.Column>
    </>);
  }

  renderRowGroupedByProject = (status) => {
    let ids = [];
    if (status === "PRE_ACCEPT" || status === "ACCEPTED") {
      const report = this.props.report.reports;
      if (!("reports" in report[0])) {
        report.map((item) => ids.push(item.id));
      } else {
        let reports = [];
        report.map((item) => reports.push(item.reports));
        let report_ids = [];
        reports.map((item) => report_ids.push(item.map((i) => i.id)));
        ids = report_ids.flat();
      }
    }

    return (<>
      <Grid.Column width={3}>
        {this.props.report.project.name}
      </Grid.Column>
      <Grid.Column width={3}>
        {this.props.report.deadline.name}
      </Grid.Column>
      <Grid.Column width={1}>
        {secondsToHm(this.props.report.total_timer_hours)}
      </Grid.Column>
      <Grid.Column width={1}>
        {secondsToHm(this.props.report.total_auto_hours)}
      </Grid.Column>
      <Grid.Column width={1}>
        <span className="hours">{secondsToHm(this.props.report.sum * 3600)}</span>
      </Grid.Column>
      <Grid.Column width={3}>
        <ReportAcceptedPopup
          report={this.props.report}
          config={config.LINGUISTS_TASK_MONTH_REPORT_HISTORY}
          ids={ids}
          status={status}/>
      </Grid.Column>
    </>);
  }

  render() {
    let clsName = "title";
    if (this.props.nested) {
      clsName = `nested-title-row ${clsName}`;
      clsName = this.props.active ? `${clsName} active` : clsName;
    } else {
      clsName = this.props.active ? "active-title-row" : clsName;
    }
    const status = this.getStatus();
    const btnAccept = this.props.auth.is_gl? null:  this.createButton(status);

    const totalHours = this.props.report.total_hours ?
      (this.props.report.total_hours).toFixed(2) : 0;

    const totalAutoHours = secondsToHm(this.props.report.total_auto_hours)
    const totalTimerHours = secondsToHm(this.props.report.total_timer_hours)

    let columns;
    switch (this.props.groupedBy) {
    case GROUPED_BY.TASK:
      columns = this.renderRowGroupedByTask(status);
      break;
    case GROUPED_BY.PROJECT:
      columns = this.renderRowGroupedByProject(status);
      break;
    case GROUPED_BY.TASK_AND_PROJECT:
      columns = this.renderRowGroupedByProject(status);
      break;
    default:
      columns = this.renderRowGroupedByUser(btnAccept, totalHours, totalAutoHours, totalTimerHours);
      break;
    }

    return (
      <Grid.Row
        className={clsName}
        onClick={(event) => {
          this.props.onClick(event, this.props.idx);
        }}
      >
        {columns}
      </Grid.Row>
    );
  }
}
