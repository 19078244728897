import React, { useState } from "react";
import {
  ModalHeader,
  ModalContent,
  ModalActions,
  Button,
  Modal,
  Icon,
} from "semantic-ui-react";


const AcceptByGLModal = ({ onAccept, status, taskReport, auth, idx = null }) => {
  const [open, setOpen] = useState(false)
  const coordinatorComment = idx !== null ? taskReport.tasks_reports[idx].coordinator_comment : taskReport.coordinator_comment

  return (
    <Modal
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      trigger={
        <Button
          color={"green"}
          size={"mini"}
          className={`accept-button datahive-button`}
          disabled={!auth.is_gl || taskReport.status === "ACCEPTED"}
        >
          <Icon name={"checkmark box"} />
          Accept
        </Button>
      }
    >
      <ModalHeader>Mismatch justification</ModalHeader>
      <ModalContent>
        {coordinatorComment}
      </ModalContent>
      <ModalActions>
        <Button color="red" onClick={() => { setOpen(false); onAccept(taskReport, false, idx); }}>
          Reject
        </Button>
        <Button
          content="Accept"
          labelPosition="right"
          icon="checkmark"
          onClick={() => {
            setOpen(false);
            onAccept(taskReport, status, idx);
          }}
          positive
        />
      </ModalActions>
    </Modal>
  );
};

export default AcceptByGLModal;
