import _ from "lodash";
import moment from "moment";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { toastr } from "react-redux-toastr";

import ajax from "../../../helpers/ajax";
import config from "../../../config/config";
import LoaderBlocker from "../../../components/LoaderBlocker";
import QuestionsSet from "../QuestionsSet";

import "./TaskNew.css";

export default class TaskNew extends Component {
  static propTypes = {
    // task config
    config: PropTypes.object.isRequired,
    // task slug
    slug: PropTypes.string.isRequired,
    // if tutorial is confirmed
    confirmed: PropTypes.bool.isRequired,
    // function triggered when new answer is submitted (for progress bar)
    onIncreaseAnswered: PropTypes.func.isRequired,
    // function is triggered every request
    onTutorialDisplayTime: PropTypes.func,
    // function is triggered when all questions is answered
    onLastQuestionAnswered: PropTypes.func,
    blocked: PropTypes.bool,
    allQuestionsAnswered: PropTypes.bool,
    // if answer may be reviewed
    reviewable: PropTypes.bool,
    // if user can start discussion
    canLinguistStartDiscussion: PropTypes.bool,
  };

  constructor(props) {
    super(props);

    this.state = {
      questions: [],
      preloadedQuestions: [],
      loading: true,
      timer: null,
      deleteAnswered: false,
      processedSubmit: null,
    };

    this.getNewUrl = config.TASK_GET_NEW_QUESTIONS;
    this.saveUrl = config.TASK_SAVE_ANSWER;
  }

  componentDidMount() {
    this.getQuestions();
  }

  questionsSetNoAnswered = (questionsToAdd) => {
    // eslint-disable-next-line
    const questions = _.map(questionsToAdd, (question, idx) => {
      return {
        ...question,
        isAnswered: false,
      };
    });

    return questions;
  };

  countAnsweredQuestions = (questions) => {
    return _.filter(questions, (obj) => {
      if (obj.isAnswered) {
        return obj;
      }
    }).length;
  };

  getQuestions = () => {
    if (!this.props.slug) {
      return;
    }
    ajax.get([this.getNewUrl, this.props.slug])
      .then((response) => {
        this.setState({ loading: false });

        // questions loaded
        const questions = response.data;
        const timer = moment();
        const questionsNA = this.questionsSetNoAnswered(questions);
        if (!questionsNA) {
          this.setState({ questions: questionsNA, timer }, () => {
            this.props.onLastQuestionAnswered();
            this.getPreloadedQuestions();
          });
        } else {
          this.setState({ questions: questionsNA, timer }, this.getPreloadedQuestions);
        }
      })
      .catch(() => {
      });
  };

  getPreloadId = (question) => {
    return question.id;
  }

  getPreloadedQuestions = () => {
    if (!this.props.slug) {
      return;
    }

    // exclude loaded questions
    // eslint-disable-next-line
    const exclude = _.map(this.state.questions, this.getPreloadId);

    ajax.get([this.getNewUrl + "?preloaded=1", this.props.slug], { params: { exclude } })
      .then((response) => {
        const preloadedQuestions = response.data;

        // if we are waiting for questions to display
        const questions = this.questionsSetNoAnswered(preloadedQuestions);
        if (this.state.questions.length === 0) {
          this.setState({ questions }, this.props.onLastQuestionAnswered);
        } else {
          this.setState({ preloadedQuestions: questions });
        }
      });
  };

  onFixAnswer = (question_id) => {
    const state_questions = this.state.questions;
    // eslint-disable-next-line
    const questions = _.map(state_questions, (question, idx) => {
      if (question_id !== question.id) {
        return { ...question };
      } else {
        /*
        "Reset" helpers, Answer can be fixed
         */
        return {
          ...question,
          isAnswered: false,
          answer_id: null,
          fix_answer: true
        };
      }
    });
    // Also reset timer
    this.setState({ questions, timer: moment() });
  };

  onSubmit = (questionIdx, widgetVals, skip = false) => {
    if (this.sendingSubmit) {
      return;
    }
    this.setState({ processedSubmit: questionIdx });
    let data = _.cloneDeep(widgetVals);
    const questionId = this.state.questions[questionIdx].id;
    if (skip) {
      data = {
        skip: true
      };
    }
    data["time_taken"] = moment() - this.state.timer;
    if (!!_.get(this.state.questions[questionIdx], "fix_answer")) {
      data["fix_answer"] = true;
    }

    this.sendingSubmit = true;
    ajax.post([this.saveUrl, this.props.slug, questionId], { data })
      .then((response) => {
        // remove answered question
        let questions = _.cloneDeep(this.state.questions);
        let callback = null;
        const timer = moment();

        // delete answered question
        if (this.state.deleteAnswered) {
          questions.splice(questionIdx, 1);
        } else {
          // mark question as answered
          questions[questionIdx].isAnswered = true;
          // Add answer_id
          questions[questionIdx].answer_id = response.data.answer_id;
          // Keep answer data if user want fix his previous answer
          questions[questionIdx]._data = data;
          // Hide button 'Fix answer'
          questions[questionIdx].fix_answer = false;
        }

        // if all questions removed or answered then add from preload and preload another
        if (!questions.length || this.countAnsweredQuestions(questions) === questions.length) {
          questions = _.cloneDeep(this.state.preloadedQuestions);
          callback = this.getPreloadedQuestions;
          window.scrollTo(0, 0);
        }

        this.props.onIncreaseAnswered();
        this.props.onTutorialDisplayTime();
        this.setState({ questions, timer, processedSubmit: null }, callback);
      })
      .catch((error) => {
        this.setState({ processedSubmit: null });
        toastr.error("Error", _.get(error, "response.data.detail") || error.message);
      })
      .finally(() => {
        this.sendingSubmit = false;
      });
  };

  render() {
    const questions = this.state.questions;

    if (this.props.blocked) {
      return (
        <div className="tab-panel TaskNew">
          <div className="Done">
            <h2>You have been blocked from answering!</h2>
          </div>
        </div>
      );
    }

    if (!this.props.confirmed) {
      return (
        <div className="tab-panel TaskNew">
          <div className="Done">
            <h2>Please accept the tutorial.</h2>
          </div>
        </div>
      );
    }

    // display spinner if questions aren't loaded yet
    if (this.state.loading) {
      return (
        <div className="tab-panel TaskNew">
          <div style={{ height: "500px" }}>
            <LoaderBlocker show={true} />
          </div>
        </div>
      );
    }

    // display no more answers
    if (!questions.length && this.props.allQuestionsAnswered) {
      /* eslint-disable react/no-unescaped-entities */
      return (
        <div className="tab-panel TaskNew">
          <div className="Done">
            <h2>You can't answer more questions!</h2>
          </div>
        </div>
      );
      /* eslint-enable react/no-unescaped-entities */
    }

    // display no more answers
    if (!questions.length) {
      return (
        <div className="tab-panel TaskNew">
          <div className="Done">
            <h2>There are no more questions.</h2>
          </div>
        </div>
      );
    }
    return (
      <div className="tab-panel TaskNew">
        <QuestionsSet
          questions={questions}
          config={this.props.config}
          onSubmit={this.onSubmit}
          isModerator={false}
          onSkip={(questionIdx, widgetVals) => {
            this.onSubmit(questionIdx, widgetVals, true);
          }}
          processedSubmit={this.state.processedSubmit}
          reviewable={this.props.reviewable}
          canLinguistStartDiscussion={this.props.canLinguistStartDiscussion}
          onFixAnswer={(questionIdx) => {
            this.onFixAnswer(questionIdx);
          }}
          isTabNew={true}
        />
      </div>
    );
  }
}
