import React, {useState, useEffect} from "react";
import { Icon, Input } from "semantic-ui-react";
import "./HoursInput.css";

const HoursInput = ({ hours, onHoursChange, onEditClick, key }) => {
  const [time, setTime] = useState(hours * 3600)
  const [timeHours, setTimeHours] = useState(Math.floor(time / 3600))
  const [timeMinutes, setTimeMinutes] = useState(Math.floor((time % 3600)/60))

  useEffect(() => {
    setTimeHours(Math.floor(time / 3600))
    setTimeMinutes(Math.floor((time % 3600)/60))
    onHoursChange(null, {value: time / 3600})
  }, [time]); 

  return (
    <div className={"hours-input"}>
      <Input
        type="number"
        className={"input right-input"}
        key={key}
        value={timeHours}
        onChange={(event, data) => {(setTime(data.value * 3600 + (timeMinutes * 60)))}}
        onBlur={onEditClick}
        size="small"
        transparent
      />
      <span style={{marginBlock: "auto", paddingBottom: "2px"}}>:</span>
      <Input
        type="number"
        className={"input"}
        key={key}
        value={timeMinutes}
        onChange={(event, data) => {(setTime(timeHours * 3600 + (data.value * 60)))}}
        onBlur={onEditClick}
        size="small"
        transparent
        max={60}
        min={0}
      />
      <div className={"arrows"}>
        <Icon name="caret up" onClick={() => setTime(time+(15*60))}/>
        <Icon name="caret up" onClick={() => time >= (15*60 - 1) && setTime(time-(15*60))} style={{ transform: "rotate(180deg)" }} />
      </div>
    </div>
  );
};

export default HoursInput;
