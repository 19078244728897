import React, {useState} from "react";
import {
  ModalHeader,
  ModalContent,
  ModalActions,
  Button,
  Modal,
  Icon,
  TextArea,
} from "semantic-ui-react";

import {
  TASK_MONTH_REPORT_STATUS_TO_GL_ACCEPT,
} from "../../constraints/Ele";

const SendToGLModal = ({ onSendToGL, status, taskReport, idx = null }) => {
  const [open, setOpen] = useState(false)
  const [coordinatorComment, setCoordinatorComment] = useState(idx !== null ? taskReport.tasks_reports[idx].coordinator_comment: taskReport.coordinator_comment)
  const reportStatus = idx !== null ? taskReport.tasks_reports[idx].status : taskReport.status
  return (
    <Modal
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      trigger={
        reportStatus === TASK_MONTH_REPORT_STATUS_TO_GL_ACCEPT? <Button
          className={"edit datahive-button"}
          color="black"
          size={"mini"}
        >
          Edit description for GL
        </Button>: 
          <Button
          color={"green"}
          size={"mini"}
          className={`accept-button datahive-button`}
          disabled={taskReport.status === "ACCEPTED"}
        >
          <Icon name={"checkmark box"} />
          Send to GL
        </Button>
      }
    >
      <ModalHeader>Provide description for Group Leader</ModalHeader>
      <ModalContent>
        <TextArea required style={{width: "100%", resize: "vertical"}} maxlength="1000" rows={8} value={coordinatorComment} onChange={(event, data) => setCoordinatorComment(data.value)}/>
      </ModalContent>
      <ModalActions>
        <Button color="red" onClick={() => setOpen(false)}>
          Cancel
        </Button>
        <Button
          content="Submit"
          labelPosition="right"
          icon="checkmark"
          onClick={() => {
            setOpen(false);
            onSendToGL(taskReport, status, coordinatorComment, idx);
          }}
          positive
        />
      </ModalActions>
    </Modal>
  );
};

export default SendToGLModal;
