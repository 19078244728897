import _ from "lodash";
import React, { Component } from "react";
import {
  Button,
  Grid,
  Icon,
  Input,
  Label,
  Modal,
  Table,
  TextArea,
} from "semantic-ui-react";
import { connect } from "react-redux";
import moment from "moment";

import ajax from "../../helpers/ajax";
import config from "../../config/config";
import history from "../../store/history";
// import DropdownMonthYear from '../../components/simple/Dropdown/DropdownMonthYear';
import { string_to_slug } from "../../helpers/utils";
import { roundHours } from "../LinguistReportsList/utils";
import FormErrors from "../FormErrors";
import Messages from "../Messages";
import { postForm } from "../../actions/actions_form";
import { errorsClear, errorsNew } from "../../actions/actions_errors";
import { messagesClear, messagesNew } from "../../actions/actions_messages";
import store from "../../store/store";
import {
  LINGUIST_MONTH_REPORT_STATUS_PENDING,
  REPORT_TYPE_LANGTASK,
  REPORT_TYPE_OTHER,
  TASK_MONTH_REPORT_STATUS_NOT_ACCEPTED,
  LINGUIST_MONTH_REPORT_STATUS_TO_GL_ACCEPT,
} from "../../constraints/Ele";

import "./LinguistReport.css";
import LoaderSpinner from "../../components/LoaderSpinner";
import PropTypes from "prop-types";
import { toastr } from "react-redux-toastr";
import InfiniteDropdown from "../../components/simple/InfiniteDropdown";
import Dropdown from "../../components/simple/Dropdown/LevenDropdown";
import { secondsToHm } from "../LinguistReportsList/utils";

// render single task row
class TaskRow extends Component {
  static propTypes = {
    errorsClear: PropTypes.func.isRequired,
    messagesClear: PropTypes.func.isRequired,
    postForm: PropTypes.func.isRequired,
    onDeleteRecord: PropTypes.func.isRequired,
    onHoursChange: PropTypes.func.isRequired,
    idx: PropTypes.number.isRequired,
    task: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.shape({
        hours: PropTypes.number,
        status: PropTypes.string,
        title: PropTypes.string,
        description: PropTypes.string,
        type: PropTypes.string,
        task: PropTypes.number,
        id: PropTypes.number,
        sessions_duration: PropTypes.number,
        rounded_sessions_duration: PropTypes.number,
        time_taken: PropTypes.number,
      }),
    ]),
    taskOptions: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    onTaskDescriptionChange: PropTypes.func.isRequired,
    onTaskPKChange: PropTypes.func.isRequired,
    status: PropTypes.string,
    deadline: PropTypes.object,
    disableEdit: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.state = {
      oldHours: 0,
      newHours: 0,
      sessions: null,
      showSessions: false,
    };
  }

  onDeleteRow = (report_id) => {
    this.props.errorsClear();
    this.props.messagesClear();
    this.props.postForm(
      config.LINGUIST_MONTH_REPORT_DELETE_RECORD,
      { report_id },
      this.props.onDeleteRecord
    );
  };

  onHoursChange = (event, data) => {
    let hours = parseFloat(data.value);
    if (_.isNaN(hours)) {
      hours = 0.0;
    }
    const { newHours } = this.state;
    hours = roundHours(hours);
    const addedHours = hours - newHours;
    this.setState((prevState) => ({
      ...prevState,
      oldHours: newHours,
      newHours: hours,
    }));
    this.props.onHoursChange(
      this.props.idx,
      this.props.task.hours + addedHours
    );
  };

  onTaskDescriptionChange = (event, data) => {
    this.props.onTaskDescriptionChange(this.props.idx, data.value);
  };

  onTaskPKChange = (event, data) => {
    this.props.onTaskPKChange(this.props.idx, data.value);
  };

  render() {
    return this.props.task.status === TASK_MONTH_REPORT_STATUS_NOT_ACCEPTED
      ? this.renderReadWrite()
      : this.renderViewOnly();
  }

  loadSessions = () => {
    if (this.state.sessions) {
      this.setState((prevState) => ({
        ...prevState,
        showSessions: !prevState.showSessions,
      }));
      return;
    }
    ajax.get([config.SESSIONS_SUMMARY, this.props.task.id]).then((response) => {
      this.setState((prevState) => ({
        ...prevState,
        sessions: response.data,
        showSessions: true,
      }));
    }).catch(() => {
      this.setState((prevState) => ({
        ...prevState,
        showSessions: true,
      }))
    });
  };

  renderViewOnly() {
    return (
      <><Table.Row key={this.props.idx}>
        <Table.Cell className={"title"} textAlign="left">
          {this.props.task.title}
        </Table.Cell>
        <Table.Cell className={"hours"}>
          Accepted - no longer editable
        </Table.Cell>
        <Table.Cell className={"total-hours"}>
            {secondsToHm(this.props.task.hours * 3600)}
          </Table.Cell>
          <Table.Cell>
            {secondsToHm(this.props.task.sessions_duration)}
          </Table.Cell>
          <Table.Cell>
            {secondsToHm(this.props.task.rounded_sessions_duration)}
          </Table.Cell>
        <Table.Cell>
            <div>
              Details{" "}
              <Icon
                onClick={() => this.loadSessions()}
                name={this.state.showSessions ? "angle up" : "angle down"}
              />
            </div>
          </Table.Cell>
          <Table.Cell>
            {this.props.task.type === "OTHER" && this.props.task.id > 0 ? (
              <Button
                size="mini"
                className="delete-row-button datahive-button"
                color="red"
                icon="delete"
                onClick={() => this.onDeleteRow(this.props.task.id)}
              />
            ) : (
              ""
            )}
          </Table.Cell>
      </Table.Row>
      <Table.Row>
      {this.state.showSessions && (
        <Table.Cell colspan={7}>
          <div
            style={{ border: "0px" }}
            className={"description"}
          >
            <div >
              <div style={{marginBottom: "12px" }} className="modal-title">
                Mismatch Justification
              </div>
              <div style={{ textAlign: "left", marginBottom: "5px" }}>{this.props.task.description}</div>
            </div>
          </div>

            {this.state.sessions && <Table.Cell colspan="6">
              <div>
                <span className="modal-title">Timer categories</span>
                <Table className="fixed-table">
                  <Table.Header>
                    {Object.keys(this.state.sessions).map((key, index) => (
                      <Table.HeaderCell key={index}>{key}</Table.HeaderCell>
                    ))}
                  </Table.Header>
                  <Table.Row>
                    {Object.values(this.state.sessions).map(
                      (key, index) => (
                        <Table.Cell key={index}>
                          {secondsToHm(key)}
                        </Table.Cell>
                      )
                    )}
                  </Table.Row>
                </Table>
              </div>
            </Table.Cell>}
        </Table.Cell>
      )}
    </Table.Row></>
    );
  }

  renderReadWrite() {
    let { title, description } = this.props.task;
    if (this.props.task.type !== REPORT_TYPE_LANGTASK) {
      title = (
        <Dropdown
          selection
          search
          options={this.props.taskOptions}
          onChange={this.onTaskPKChange}
          value={this.props.task.task}
          style={{width: "100%"}}
        />
      );
    }

    description = (
      <TextArea
        style={{ width: "100%", resize: "vertical" }}
        onChange={this.onTaskDescriptionChange}
        value={this.props.task.description}
        placeholder="Describe reasons of time mismatch"
        rows={4}
      />
    );

    return (
      <>
        <Table.Row key={this.props.idx}>
          <Table.Cell className={"title"} textAlign="left">
            {title}
          </Table.Cell>
          <Table.Cell className={"hours"}>
            <Input
              className={"task-report-hours-input"}
              type="number"
              value={this.state.newHours}
              onChange={this.onHoursChange}
              disabled={this.props.disableEdit}
              onFocus={()=> !this.state.showSessions && this.loadSessions()}
              step={0.25}
              min={0}
              size="mini"
            />
          </Table.Cell>
          <Table.Cell className={"total-hours"}>
            {secondsToHm(this.props.task.hours * 3600)}
          </Table.Cell>
          <Table.Cell style={{ color: "darkgrey" }}>
            {secondsToHm(this.props.task.sessions_duration)}
          </Table.Cell>
          <Table.Cell>
            {secondsToHm(this.props.task.rounded_sessions_duration)}
          </Table.Cell>
          <Table.Cell>
            <div>
              Details{" "}
              <Icon
                onClick={() => this.loadSessions()}
                name={this.state.showSessions ? "angle up" : "angle down"}
              />
            </div>
          </Table.Cell>
          <Table.Cell>
            {this.props.task.type === "OTHER" && this.props.task.id > 0 ? (
              <Button
                size="mini"
                className="delete-row-button datahive-button"
                color="red"
                icon="delete"
                onClick={() => this.onDeleteRow(this.props.task.id)}
              />
            ) : (
              ""
            )}
          </Table.Cell>
        </Table.Row>
        <Table.Row>
          {this.state.showSessions && (
            <Table.Cell colspan={7}>
              <div
                style={{ border: "0px" }}
                className={"description"}
                textAlign="left"
              >
                <div style={{ textAlign: "center" }}>
                  <div style={{ marginBottom: "12px" }} className="modal-title">
                   Manual hours justification
                  </div>
                  {description}
                </div>
              </div>

                {this.state.sessions && <Table.Cell colspan="6">
                  <div>
                    <span className="modal-title">Timer categories</span>
                    <Table className="fixed-table">
                      <Table.Header>
                        {Object.keys(this.state.sessions).map((key, index) => (
                          <Table.HeaderCell key={index}>{key}</Table.HeaderCell>
                        ))}
                      </Table.Header>
                      <Table.Row>
                        {Object.values(this.state.sessions).map(
                          (key, index) => (
                            <Table.Cell key={index}>
                              {secondsToHm(key)}
                            </Table.Cell>
                          )
                        )}
                      </Table.Row>
                    </Table>
                  </div>
                </Table.Cell>}
            </Table.Cell>
          )}
        </Table.Row>
      </>
    );
  }
}

// render report status
class Status extends Component {
  static propTypes = {
    status: PropTypes.string.isRequired,
  };

  render() {
    return (
      <Label
        className={string_to_slug(this.props.status) + " status-label"}
        size={"big"}
        basic
      >
        {this.props.status}
      </Label>
    );
  }
}

// render Deadline
const Deadline = (props) => {
  const start = _.get(props.deadline, "start")
    ? moment(_.get(props.deadline, "start")).format("DD.MM.YYYY HH:mm")
    : "";
  const end = _.get(props.deadline, "end")
    ? moment(_.get(props.deadline, "end")).format("DD.MM.YYYY HH:mm")
    : "";

  return (
    <Grid columns={4} className={""}>
      <Grid.Column />
      <Grid.Column>Period from:</Grid.Column>
      <Grid.Column className={"date-margin"}>{start}</Grid.Column>
      <Grid.Column />
      <Grid.Column />
      <Grid.Column>Closing date:</Grid.Column>
      <Grid.Column className={"date-margin"}>{end}</Grid.Column>
      <Grid.Column />
    </Grid>
  );
};

Deadline.propTypes = {
  deadline: PropTypes.object,
};

const ConnectedRow = connect(
  (state) => {
    return {
      auth: state.auth,
    };
  },
  {
    postForm,
    errorsClear,
    errorsNew,
    messagesClear,
  }
)(TaskRow);

export class LinguistReport extends Component {
  static propTypes = {
    auth: PropTypes.shape({
      is_company_coordinator: PropTypes.bool,
      is_staff: PropTypes.bool,
    }).isRequired,
    errorsClear: PropTypes.func.isRequired,
    messagesClear: PropTypes.func.isRequired,
    postForm: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      report: null,
      selectedUser: null,
      selectedPeriod: null,
      usersOptions: [],
      display_spinner: false,
      deadlines: [],
      selectedCompanyId: null,
      selectedDeadlineId: null,
      noErrors: true,
    };
  }

  componentDidMount() {
    this.getUsers();

    if (!this.props.auth.is_staff) {
      this.getDeadlines(null, this.getReport);
      this.getTasks();
    } else {
      this.getReport();
      this.getCompanies();
    }
  }

  getReport = (deadlineId) => {
    if (
      this.props.auth.is_staff &&
      (!this.state.selectedCompanyId || !this.state.selectedDeadlineId)
    ) {
      return;
    }

    if (
      this.props.auth.is_company_coordinator &&
      !this.state.selectedDeadlineId
    ) {
      return;
    }

    const canReportOthers =
      this.props.auth.is_staff || this.props.auth.is_company_coordinator;
    const data = deadlineId ? { deadline_id: deadlineId } : {};
    if (canReportOthers && !!data && !!this.state.selectedUser) {
      data.user = this.state.selectedUser;
    }

    this.props.errorsClear();
    this.props.messagesClear();
    const _this = this;
    this.setState({ display_spinner: true });
    ajax
      .get(config.LINGUIST_MONTH_REPORT, { params: data })
      .then((response) => {
        _this.setState(
          {
            report: response.data,
          },
          () => _this.setState({ display_spinner: false })
        );
      })
      .catch((error) => {
        this.setState({ noErrors: false });
        toastr.error(error.response.data.detail);
      });
  };

  getUsers = (selectedCompanyId) => {
    if (this.props.auth.is_staff || this.props.auth.is_company_coordinator) {
      this.props.errorsClear();
      this.props.messagesClear();
      const _this = this;
      const params = {};
      if (selectedCompanyId) {
        params.company_id = selectedCompanyId;
      }
      this.setState({ display_spinner: true });
      ajax.get(config.USERS_IN_DIVISION, { params }).then((response) => {
        this.setState(
          {
            users: response.data,
            usersOptions: this.getUserOptions(response.data),
          },
          () => _this.setState({ display_spinner: false })
        );
      });
    }
  };

  // go back to last page
  closeModal = () => {
    history.goBack();
  };

  onHoursChange = (idx, hours) => {
    var report = { ...this.state.report };
    report.tasks_reports[idx].hours = hours;
    this.setState({
      report,
    });
  };

  onTaskDescriptionChange = (idx, description) => {
    var report = { ...this.state.report };
    report.tasks_reports[idx].description = description;
    this.setState({
      report,
    });
  };

  onTaskPKChange = (idx, pk) => {
    var report = { ...this.state.report };
    report.tasks_reports[idx].task = pk;
    this.setState({
      report,
    });
  };

  getDeadlines = (company_id, nextFunc) => {
    ajax
      .get(config.LINGUISTS_MONTH_REPORTS_DEADLINE, { params: { company_id } })
      .then((response) => {
        const newState = { deadlines: response.data };
        if (!this.state.selectedDeadlineId) {
          newState.selectedDeadlineId = _.first(response.data).id;
        }
        this.setState(newState);
        if (nextFunc) {
          nextFunc(newState.selectedDeadlineId);
        }
      })
      .catch(() => {
        this.setState({ deadlines: [] });
      });
  };

  getTasks = (user = null) => {
    if (!user) {
      user = -1;
    }
    ajax.get([config.USER_TASKS_WITH_IDS, user]).then((response) => {
      if (response.data) {
        this.setState({ taskOptions: this.getTaskOptions(response.data) });
      } else {
        this.setState({ taskOptions: [] });
      }
    });
  };

  onCompanyChange = (e, data) => {
    const selectedCompanyId = data.value;
    this.setState(
      {
        selectedDeadlineId: null,
        report: null,
        selectedCompanyId,
      },
      () => {
        this.getDeadlines(selectedCompanyId);
        this.getUsers(selectedCompanyId);
      }
    );
  };

  onDeadlineChange = (e, data) => {
    const selectedDeadlineId = data.value;
    this.setState({ selectedDeadlineId });
    this.getReport(selectedDeadlineId);
  };

  /* eslint-disable-next-line no-unused-vars */
  onAddNewOtherTask = (event, data) => {
    const report = { ...this.state.report };
    const newTask = {
      id: null,
      type: REPORT_TYPE_OTHER,
      task: null,
      description: "",
      hours: 0.0,
      status: TASK_MONTH_REPORT_STATUS_NOT_ACCEPTED,
    };
    report.tasks_reports.push(newTask);
    this.setState({
      report,
    });
  };

  /* eslint-disable-next-line no-unused-vars */
  onSubmit = (event, data) => {
    this.props.errorsClear();
    this.props.messagesClear();
    this.props.postForm(
      config.LINGUIST_MONTH_REPORT,
      this.state.report,
      this.onSaved
    );
  };

  onSaved = (result) => {
    var report = { ...this.state.report };
    report.status = LINGUIST_MONTH_REPORT_STATUS_PENDING;
    this.setState({
      report,
    });
    // Reload after save, and store message from submitted form
    this.getReport(report.deadline);
    store.dispatch(messagesNew(result.data));
  };

  onReportUserChange = (data) => {
    this.setState({ selectedUser: data }, () => {
      this.getTasks(data);
      this.getReport(this.state.selectedDeadlineId);
    });
  };

  getUserOptions = (data) => {
    /* eslint-disable-next-line no-unused-vars */
    return _.map(data, (user) => {
      return {
        key: user.id,
        text: user.username,
        value: user.id,
      };
    });
  };

  getTaskOptions = (data) => {
    return _.map(data, (task) => {
      return {
        key: task.taskId,
        value: task.taskId,
        text: task.title,
      };
    });
  };

  getCompanies = () => {
    ajax.get(config.COMPANY_OPTIONS).then((response) => {
      const companies = response.data;
      if (this.props.auth.is_staff) {
        companies.push({
          id: -1,
          name: "No company assigned",
        });
      }
      this.setState({
        companies: companies,
      });
    });
  };

  renderReport = () => {
    if (!this.state.report) {
      return "";
    }
    if (this.state.display_spinner) {
      return <LoaderSpinner />;
    }
    const tasks = this.state.report.tasks_reports;
    const rows = _.map(tasks, (task, idx) => {
      return (
        <ConnectedRow
          key={idx}
          task={task}
          idx={idx}
          taskOptions={this.state.taskOptions}
          onHoursChange={this.onHoursChange}
          onTaskDescriptionChange={this.onTaskDescriptionChange}
          onTaskPKChange={this.onTaskPKChange}
          onDeleteRecord={(result) => {
            this.getReport(this.state.selectedDeadlineId);
            store.dispatch(messagesNew(result.data));
          }}
          disableEdit={!this.props.auth.is_staff && this.showWarnings()}
        />
      );
    });

    var addTask = null;
    if (this.props.auth.is_staff || !this.showWarnings()) {
      addTask = (
        <Table.Row>
          <Table.Cell colSpan="7">
            <Button
              floated="left"
              className={"add-button"}
              onClick={this.onAddNewOtherTask}
            >
              Add task
            </Button>
          </Table.Cell>
        </Table.Row>
      );
    }

    if (rows.length || addTask) {
      return (
        <Table className={"report-status"} textAlign={"center"}>
          <Table.Header fullWidth>
            <Table.Row>
              <Table.HeaderCell>Task</Table.HeaderCell>
              <Table.HeaderCell>Add manual</Table.HeaderCell>
              <Table.HeaderCell>Manual </Table.HeaderCell>
              <Table.HeaderCell>Timer</Table.HeaderCell>
              <Table.HeaderCell>Paid timer</Table.HeaderCell>
              <Table.HeaderCell />
              <Table.HeaderCell />
            </Table.Row>
          </Table.Header>
          <Table.Body style={{ fontSize: "12px" }}>
            {rows}
            {addTask}
          </Table.Body>
        </Table>
      );
    }
    return null;
  };

  renderWarning = () => {
    if (!this.props.auth.is_staff && this.showWarnings()) {
      return (
        <>
          <Grid.Column className="error-column" width={4} />
          <Grid.Column className="error-column" width={8} textAlign={"center"}>
            <div>{"It's too late to report hours for this deadline."}</div>
            <div>{"Please consult with coordinators."}</div>
          </Grid.Column>
          <Grid.Column className="error-column" width={4} />
        </>
      );
    }
    return null;
  };

  renderSubmitButton = () => {
    return (
      <Modal.Actions className={"centered"}>
        {this.state.noErrors && (
          <Button
            onClick={this.onSubmit}
            className="datahive-button"
            color="yellow"
            disabled={!this.props.auth.is_staff && this.showWarnings()}
          >
            Submit
          </Button>
        )}
      </Modal.Actions>
    );
  };

  showWarnings = () => {
    let deadline = _.find(this.state.deadlines, {
      id: this.state.selectedDeadlineId,
    });
    return !!deadline && moment().diff(deadline["end"]) > 0;
  };

  render() {
    const canReportOthers =
      this.props.auth.is_staff || this.props.auth.is_company_coordinator;
    const status = this.state.report ? (
      <Status status={this.state.report.status === LINGUIST_MONTH_REPORT_STATUS_TO_GL_ACCEPT? LINGUIST_MONTH_REPORT_STATUS_PENDING: this.state.report.status } />
    ) : (
      <div />
    );

    return (
      <Modal
        dimmer={true}
        open={true}
        onClose={this.closeModal}
        className={"LinguistReportModal"}
      >
        <Modal.Header>
          <div>
            <Icon name={"calculator"} />
            <span>Monthly report</span>
          </div>
          <div className={"close-button"} onClick={this.closeModal}>
            <Icon name={"window close"} />
          </div>
        </Modal.Header>
        <Modal.Content>
          <Grid columns={4} className={"DropdownMonthYear"}>
            {this.props.auth.is_staff && (
              <>
                <Grid.Column />
                <Grid.Column className={"horizontal-center"}>
                  Company:
                </Grid.Column>
                <Grid.Column className={"item-centered"}>
                  <Dropdown
                    placeholder={"Select company"}
                    className={"company-dropdown"}
                    fluid
                    search
                    selection
                    options={_.map(this.state.companies, (c) => {
                      return {
                        value: c.id,
                        text: c.name ? c.name : c.id,
                      };
                    })}
                    value={
                      this.state.selectedCompanyId
                        ? this.state.selectedCompanyId
                        : null
                    }
                    onChange={this.onCompanyChange}
                  />
                </Grid.Column>
                <Grid.Column />
              </>
            )}
            {canReportOthers && (
              <>
                <Grid.Column />
                <Grid.Column className={"horizontal-center"}>User:</Grid.Column>
                <Grid.Column className={"item-centered"}>
                  <InfiniteDropdown
                    className={"user-dropdown"}
                    chunkSize={10}
                    placeholder={
                      this.state.usersOptions.length > 0
                        ? "Select User"
                        : "Loading..."
                    }
                    fluid
                    search
                    selection
                    disabled={this.state.usersOptions.length === 0}
                    choices={this.state.usersOptions}
                    value={this.state.selectedUser}
                    onChange={(e, data) => this.onReportUserChange(data.value)}
                  />
                </Grid.Column>
                <Grid.Column />
              </>
            )}
            <Grid.Column />
            <Grid.Column className={"horizontal-center"}>Deadline:</Grid.Column>
            <Grid.Column className={"item-centered"}>
              <Dropdown
                placeholder={"Select deadline"}
                className={"deadline-dropdown"}
                fluid
                search
                selection
                options={_.map(this.state.deadlines, (d) => {
                  return {
                    value: d.id,
                    text: d.name ? d.name : d.id,
                  };
                })}
                value={
                  this.state.selectedDeadlineId
                    ? this.state.selectedDeadlineId
                    : null
                }
                onChange={this.onDeadlineChange}
              />
            </Grid.Column>
            <Grid.Column />
            {this.renderWarning()}
          </Grid>
          <Deadline
            deadline={_.find(this.state.deadlines, {
              id: this.state.selectedDeadlineId,
            })}
          />
          <Grid className={"last-grid"} columns={4}>
            <Grid.Column />
            <Grid.Column>Status:</Grid.Column>
            <Grid.Column className={"item-centered"}>{status}</Grid.Column>
            <Grid.Column />
          </Grid>
          {this.renderReport()}
          <FormErrors />
          <Messages />
        </Modal.Content>
        {this.renderSubmitButton()}
      </Modal>
    );
  }
}

export default connect(
  (state) => {
    return {
      auth: state.auth,
    };
  },
  {
    postForm,
    errorsClear,
    errorsNew,
    messagesClear,
  }
)(LinguistReport);
