import React, { useState } from "react";
import PropTypes from "prop-types";
import { Icon, Input, Select, TableCell, TableRow } from "semantic-ui-react";
import { categoryOptions, formatDuration } from "./Timer";
import ajax from "../../../helpers/ajax";
import config from "../../../config/config";
import { toastr } from "react-redux-toastr";
import moment from "moment";
import _ from "lodash";
import "./SessionRow.css";

const SessionRow = ({ session, getSessions }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [category, setCategory] = useState(session.category);
    const [note, setNote] = useState(session.note);
    const timezone = moment.tz.guess();

    const formatTime = (timeStamp) => {
        return moment.tz(timeStamp, timezone).format("HH:mm:ss");
    };

    const handleChange = () => {
        const data = {
            category: category,
            note: note
        };
        ajax.patch([config.UPDATE_SESSION, session.id], { data })
            .then(() => {
                toastr.success("Session updated");
                setIsEditing(false);
                getSessions();
            })
            .catch((errors) => {
                const err = JSON.stringify(_.get(errors, "response.data", "Something went wrong!"));
                toastr.error("Error!", err);
            });
    };

    return (
        <TableRow>
            <TableCell collapsing>
                {isEditing ? (
                    <div>
                        <Select
                            placeholder="Select category"
                            value={category}
                            onChange={(e, { value }) => setCategory(value)}
                            options={categoryOptions}
                        />
                    </div>
                ) : (
                    <div>
                        {category}
                    </div>
                )}
            </TableCell>
            <TableCell collapsing >{session.duration === null ? "IN PROGRESS" : formatDuration(session.duration)}</TableCell>
            <TableCell collapsing>{session.duration === null ? `${formatTime(session.start)} - ` : `${formatTime(session.start)} - ${formatTime(session.end)}`}</TableCell>
            <TableCell >
                {isEditing ? (
                    <Input
                        fluid
                        type="text"
                        value={note || ""}
                        onChange={(e, { value }) => setNote(value)}
                        maxLength={100}
                    />
                ) : (
                    <div>{note}</div>
                )
                }

            </TableCell>
            <TableCell collapsing>
                {isEditing ? (
                    <button
                        className="icon-button"
                        onClick={handleChange}>
                        <Icon name="check" color="green" />
                    </button>
                ) : (
                    <button
                        disabled={session.duration === null}
                        className="icon-button"
                        onClick={() => setIsEditing(true)}>
                        <Icon name="edit outline" color="grey" />
                    </button>
                )}
            </TableCell>
        </TableRow>
    );
};

SessionRow.propTypes = {
    session: PropTypes.object.isRequired,
    getSessions: PropTypes.func.isRequired,
};

export default SessionRow;
